import { Component, OnInit, Input } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-offer-workshop-rate",
  templateUrl: "./offer-workshop-rate.component.html",
  styleUrls: ["./offer-workshop-rate.component.scss"],
})
export class OfferWorkshopRateComponent implements OnInit {
  comment: string = "";
  rateForm: FormGroup;
  @Input("supplier") supplier;

  constructor(
    private shanabService: ShanabService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.rateForm = this.formBuilder.group({
      supplier: [
        this.supplier ? this.supplier : "5f37d4558307d703c68b5f97",
        Validators.required,
      ],
      rateTime: [0, Validators.required],
      rateService: [0, Validators.required],
      rateQuality: [0, Validators.required],
      rateComment: ["", Validators.required],
    });
  }

  addRate() {
    this.shanabService.addRate(this.rateForm.value).subscribe((res) => {});
  }

  setRateCount(feild, value) {
    this.rateForm.get(feild).setValue(value);
  }
}
