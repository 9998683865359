import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workshop-user-details-comment',
  templateUrl: './workshop-user-details-comment.component.html',
  styleUrls: ['./workshop-user-details-comment.component.scss']
})
export class WorkshopUserDetailsCommentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
