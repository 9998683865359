import { Component, OnInit, Input } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-offer-accepet-card",
  templateUrl: "./offer-accepet-card.component.html",
  styleUrls: ["./offer-accepet-card.component.scss"],
})
export class OfferAccepetCardComponent implements OnInit {
  map = false;
  rate = false;
  offer: any;
  @Input("offerId") offerId;

  constructor(private shanabService: ShanabService) {}

  ngOnInit() {
    this.getOffer();
  }

  getOffer() {
    this.shanabService.getOffer(this.offerId).subscribe((res: any) => {
      if (res) {
        this.offer = res.data[0];
      }
    });
  }

  acceptOffer(i) {
    this.shanabService.acceptOffer(this.offerId).subscribe((res) => {
      this.offer.accept = true;
    });
  }

  transporCarRequest() {
    this.map = true;
    this.rate = false;
  }

  goMap() {
    window.open(
      `http://www.google.com/maps/place/${this.offer.supplier.location.coordinates[0]},${this.offer.supplier.location.coordinates[1]}`
    );
  }

  rateThisWorkshop() {
    this.rate = !this.rate;
    this.map = false;
  }
}
