import { Component, OnInit, Input } from '@angular/core';
import { NavParamsService } from '../../services/nav-params.service';

@Component({
  selector: 'app-card-workshop',
  templateUrl: './card-workshop.component.html',
  styleUrls: ['./card-workshop.component.scss'],
})
export class CardWorkshopComponent implements OnInit {
  shop: any = {};

  constructor(private navParams: NavParamsService) {}

  ngOnInit() {
    if (this.navParams.params && this.navParams.params['shop']) {
      this.shop = this.navParams.params['shop'];
    }
  }
}
