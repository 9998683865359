import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Location } from "@angular/common";

@Component({
  selector: "app-create-offer2",
  templateUrl: "./create-offer.component.html",
  styleUrls: ["./create-offer.component.scss"],
})
export class CreateOfferComponent2 implements OnInit {
  order: any;
  form: FormGroup;
  done = false;
  constructor(
    private shanab: ShanabService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.shanab.getOneOrder(this.route.snapshot.params.id).subscribe((data) => {
      this.order = data.data[0];
    });

    this.form = new FormGroup({
      order: new FormControl(this.route.snapshot.params.id, [
        Validators.required,
      ]),
      price: new FormControl("", [Validators.required]),
      workingDays: new FormControl("", [Validators.required]),
      comment: new FormControl("", [Validators.required]),
    });
  }

  confirmClicked() {
    this.shanab.addOfferForOrder(this.form.value).subscribe((data) => {
      this.done = true;

      setTimeout(() => {
        this._location.back();
      }, 4000);
    });
  }
}
