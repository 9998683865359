import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { NavParamsService } from "../../services/nav-params.service";

@Component({
  selector: "app-pay-to-show-shops-page",
  templateUrl: "./pay-to-show-shops-page.component.html",
  styleUrls: ["./pay-to-show-shops-page.component.scss"],
})
export class PayToShowShopsPageComponent implements OnInit {
  orderId: string;
  paymentDetails: any = {};

  constructor(
    private router: Router,
    private shanabService: ShanabService,
    private navParams: NavParamsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.orderId = this.route.snapshot.params.orderId;
    this.getPaymentDetails();
  }

  getPaymentDetails() {
    this.shanabService.getPaymentDetails().subscribe((res) => {
      this.paymentDetails = res.amount;
    });
  }

  pay() {
    this.shanabService.pay(this.orderId).subscribe((res) => {
      this.paymentDetails.payment_url = res.payment_url;
      window.open(this.paymentDetails.payment_url, "_self");
    });
  }

  payToShowCard() {
    this.router.navigate(["/order-offers"]);
  }
}
