import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-download-home",
  templateUrl: "./download-home.component.html",
  styleUrls: ["./download-home.component.scss"],
})
export class DownloadHomeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  open(url) {
    window.open(url);
  }
}
