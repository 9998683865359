import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-contact-home",
  templateUrl: "./contact-home.component.html",
  styleUrls: ["./contact-home.component.scss"]
})
export class ContactHomeComponent implements OnInit {
  form: FormGroup;
  constructor(private shanab: ShanabService) {}

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, CustomValidators.email]),
      phone: new FormControl("", [
        Validators.required,
        CustomValidators.number
      ]),
      message: new FormControl("", [Validators.required])
    });
  }

  valid(controlName, errorName) {
    return (
      this.form.get(controlName).hasError(errorName) &&
      (this.form.get(controlName).dirty || this.form.get(controlName).touched)
    );
  }

  send() {
    this.shanab.sendMessage(this.form.value).subscribe((data) => {
      this.form.reset();
    });
  }
}
