import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-place-direct-order-pgae",
  templateUrl: "./place-direct-order-pgae.component.html",
  styleUrls: ["./place-direct-order-pgae.component.scss"],
})
export class PlaceDirectOrderPgaeComponent implements OnInit {
  step = [];
  shop: any = {};
  orderForm: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private shanabService: ShanabService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.initForm();
    this.orderForm.get("location").setValue(localStorage.getItem("location"));
    this.orderForm.get("supplier").setValue(this.route.snapshot.params.shopId);
  }

  initForm() {
    this.orderForm = this.fb.group({
      supplier: ["", Validators.required],
      myCar: ["", Validators.required],
      orderType: ["", Validators.required],
      comment: ["", Validators.required],
      location: [{}, Validators.required],
      images: [[], Validators.required],
    });
  }

  valueChanged(e?) {
    this.step.push(1);
  }

  backValueChanged() {
    this.step.pop();
  }

  sendOrder(e) {
    this.orderForm.get("comment").setValue(e.comment);
    this.orderForm.get("images").setValue(e.images);
    this.orderForm.get("myCar").setValue(e.myCar);

    this.shanabService
      .uploadFile(this.orderForm.get("images").value)
      .subscribe((res) => {
        const orderData = {
          myCar: this.orderForm.get("myCar").value,
          orderType: this.orderForm.get("orderType").value,
          comment: this.orderForm.get("comment").value,
          location: this.orderForm.get("location").value,
          supplier: this.orderForm.get("supplier").value,
          images: res.data,
        };

        this.shanabService
          .addDirectOrder(orderData)
          .subscribe((result: any) => {
            this.router.navigate(["myorders"]);
          });
      });
  }

  setType(e) {
    this.orderForm.get("orderType").setValue(e);
  }
}
