import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-request-details",
  templateUrl: "./request-details.component.html",
  styleUrls: ["./request-details.component.scss"],
})
export class RequestDetailsComponent implements OnInit {
  o: any = {};
  orderId: any;

  constructor(private shanab: ShanabService, private route: ActivatedRoute) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.orderId = this.route.snapshot.params.id;
    this.getOrder();
  }

  getOrder() {
    this.shanab.getOneOrder(this.orderId).subscribe((res) => {
      if (res) {
        this.o = res.data[0];
      }
    });
  }
}
