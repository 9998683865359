import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-place-order-step1',
  templateUrl: './place-order-step1.component.html',
  styleUrls: ['./place-order-step1.component.scss']
})
export class PlaceOrderStep1Component implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    this.nextChange.emit();
  }
}
