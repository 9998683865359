import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { LanguageService } from "./language.service";

@Injectable({
  providedIn: "root",
})
export class HttpIntercept implements HttpInterceptor {
  constructor(private lang: LanguageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (localStorage.getItem("auth-token")) {


      
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          lang: localStorage.getItem('lang')
            ? localStorage.getItem('lang').toUpperCase()
            : "AR",
          // lang: this.lang.selectedLanguage.value
          //   ? this.lang.selectedLanguage.value.toUpperCase()
          //   : "AR",
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          lang: localStorage.getItem('lang')
          ? localStorage.getItem('lang').toUpperCase()
          : "AR",
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          // server side errors
          if (error.status === 409) {
          }
          return throwError(error);
        } else {
          // client side errors
          return throwError(error);
        }
      })
    );
  }
}
