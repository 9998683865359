import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./home/home.component";
import { RegisterComponent } from "./register/register.component";
import { PlaceOrderComponent } from "./place-order/place-order.component";
import { MyOrdersPageComponent } from "./my-orders-page/my-orders-page.component";
import { OurShopsPageComponent } from "./our-shops-page/our-shops-page.component";
import { PlaceDirectOrderPgaeComponent } from "./place-direct-order-pgae/place-direct-order-pgae.component";
import { OrderDetilsPageComponent } from "./order-detils-page/order-detils-page.component";
import { ProfilePageComponent } from "./profile-page/profile-page.component";
import { VehiclesPageComponent } from "./vehicles-page/vehicles-page.component";
import { HelpPageComponent } from "./help-page/help-page.component";
import { TicketsPageComponent } from "./tickets-page/tickets-page.component";
import { NotificationPageComponent } from "./notification-page/notification-page.component";
import { PayToShowShopsPageComponent } from "./pay-to-show-shops-page/pay-to-show-shops-page.component";
import { OrderDetailsAfterPayShowComponent } from "./order-details-after-pay-show/order-details-after-pay-show.component";
import { OfferAccepetPageComponent } from "./offer-accepet-page/offer-accepet-page.component";
import { PayToTransportComponent } from "./pay-to-transport/pay-to-transport.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { TermsComponent } from "./terms/terms.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SupplierShopServiceComponent } from "./supplier-shop-service/supplier-shop-service.component";
import { SupplierRequestsComponent } from "./supplier-requests/supplier-requests.component";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AddVechileComponent } from "./add-vechile/add-vechile.component";
import { RouterModule } from "@angular/router";
import { AddTicketComponent } from "./add-ticket/add-ticket.component";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { OurShopsDetailsComponent } from "./our-shops-details/our-shops-details.component";
import { SupplierOffersComponent } from "./supplier-offers/supplier-offers.component";
import { CreateOfferComponent2 } from "./create-offer/create-offer.component";
import { RequestDetailsComponent } from "./request-details/request-details.component";
import { SellYourCarComponent } from "./sell-your-car/sell-your-car.component";
import { AddSellCarComponent } from "./add-sell-car/add-sell-car.component";
import { AddSellCarDetailsComponent } from "./add-sell-car-details/add-sell-car-details.component";
import { SuplierBrandsComponent } from "./suplier-brands/suplier-brands.component";
import { ReturnPolicyComponent } from "./return-policy/return-policy.component";
import { NgxGalleryModule } from "ngx-gallery";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ChatComponent } from "./chat/chat.component";
import { ChatListingPage } from "./chat-listing/chat-listing.component";
import { SharedModule } from "../components/shared.module";
import { SearchCarsComponent } from './search-cars/search-cars.component';

@NgModule({
  declarations: [
    HomeComponent,
    RegisterComponent,
    PlaceOrderComponent,
    MyOrdersPageComponent,
    OurShopsPageComponent,
    PlaceDirectOrderPgaeComponent,
    OrderDetilsPageComponent,
    ProfilePageComponent,
    VehiclesPageComponent,
    HelpPageComponent,
    TicketsPageComponent,
    NotificationPageComponent,
    PayToShowShopsPageComponent,
    OrderDetailsAfterPayShowComponent,
    OfferAccepetPageComponent,
    PayToTransportComponent,
    PageNotFoundComponent,
    TermsComponent,
    PrivacyComponent,
    SupplierShopServiceComponent,
    SupplierRequestsComponent,
    AddVechileComponent,
    AddTicketComponent,
    OurShopsDetailsComponent,
    SupplierOffersComponent,
    CreateOfferComponent2,
    RequestDetailsComponent,
    SellYourCarComponent,
    AddSellCarComponent,
    AddSellCarDetailsComponent,
    SuplierBrandsComponent,
    ReturnPolicyComponent,
    ChangePasswordComponent,
    EditProfileComponent,
    ChatComponent,
    ChatListingPage,
    SearchCarsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    NgbPaginationModule,
    NgxGalleryModule
  ],
  exports: []
})
export class SharedPageModule {}
