import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-place-direct-order-step3",
  templateUrl: "./place-direct-order-step3.component.html",
  styleUrls: ["./place-direct-order-step3.component.scss"],
})
export class PlaceDirectOrderStep3Component implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  // tslint:disable-next-line: no-input-rename
  @Input("text") text = "Next";
  @ViewChild("orderForm", { static: false }) orderForm;

  selectcar = false;
  selectcar2 = false;
  image: any;
  addimage = false;
  images = [];

  constructor(private router: Router) {}

  ngOnInit() {}

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    this.nextChange.emit(this.getData());
  }

  selectCar() {
    this.selectcar = !this.selectcar;
  }

  getData() {
    return this.orderForm.getData();
  }
  chooseImage(event) {
    const image: File = event.target.files[0];
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.images.push(this.image);
    };
    myReader.readAsDataURL(image);
    this.addimage = true;
  }
}
