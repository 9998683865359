import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NavParamsService } from "../../services/nav-params.service";

@Component({
  selector: "app-our-shops-details",
  templateUrl: "./our-shops-details.component.html",
  styleUrls: ["./our-shops-details.component.scss"],
})
export class OurShopsDetailsComponent implements OnInit {
  comments = [];
  shop: any = {};
  constructor(
    private shanab: ShanabService,
    private router: Router,
    private route: ActivatedRoute,
    private navParams: NavParamsService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.getData();
  }

  getData() {
    // this.shanab.getOneShop(this.route.snapshot.params.id).subscribe((data) => {
    // });
    this.shanab
      .getSupplierComments(this.route.snapshot.params.id)
      .subscribe((data) => {
        this.shop = data.data[0];
        this.navParams.setValue({ shop: this.shop });

        this.comments = this.shop.rates.ratesAnsUsers;
      });
  }

  goToPlaceOrder() {
    this.router.navigate(["/place-direct-order/" + this.shop._id]);
  }
}
