import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-home',
  templateUrl: './register-home.component.html',
  styleUrls: ['./register-home.component.scss']
})
export class RegisterHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
