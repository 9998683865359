import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { NavParamsService } from "../../services/nav-params.service";

@Component({
  selector: "app-our-shops-page",
  templateUrl: "./our-shops-page.component.html",
  styleUrls: ["./our-shops-page.component.scss"],
})
export class OurShopsPageComponent implements OnInit {
  cities = [];
  orderTypes = [];
  city = "";
  types = "";
  shops = [];
  page = 1;
  paging: any;
  searchWord = "";
  loading: boolean = false;

  constructor(
    private shanab: ShanabService,
    private navParams: NavParamsService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.shanab.getAllCities().subscribe((data) => {
      this.cities = data.data;
    });
    this.shanab.getAllOrderTypes().subscribe((data) => {
      this.orderTypes = data.data;
    });
    this.getData();
  }

  searchClicked() {
    this.page = 1;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.shanab
      .getOurShops(this.page, this.searchWord, {
        city: this.city,
        types: this.types,
      })
      .subscribe(
        (data) => {
          this.shops = data.data;
          this.paging = data.paging;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  search() {
    this.getData();
  }

  goToWorkshopDetails(shop) {
    this.navParams.setValue({ shop: shop });
  }

  changePage(event) {
    if (event) {
      this.page = event;
    }
    this.getData();
  }
}
