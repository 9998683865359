import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavParamsService {
  public params: any;

  constructor() { }

  setValue(data: any) {
    this.params = data;
  }

  getValue(param) {
    return this.params === param;
  }
}
