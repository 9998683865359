import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  AfterViewInit,
} from "@angular/core";

declare var google;

@Component({
  selector: "app-google-map",
  templateUrl: "./google-map.component.html",
  styleUrls: ["./google-map.component.scss"],
})
export class GoogleMapComponent implements OnInit, AfterViewInit {
  @Output("location") location = new EventEmitter();
  @Input("currentLocation") currentLocation;

  map: any;
  marker: any;
  @ViewChild("mapElement", { static: false }) mapElement;
  mapOptions: any = {};

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadMap();
    }, 1000);
  }

  loadMap() {
    this.mapOptions = {
      center: {
        lat: this.currentLocation && this.currentLocation.coordinates ? this.currentLocation.coordinates[0] : 24.774265,
        lng: this.currentLocation && this.currentLocation.coordinates ? this.currentLocation.coordinates[1] : 46.738586
      },
      zoom: 8,
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#e9e9e9",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#dedede",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              saturation: 36,
            },
            {
              color: "#333333",
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [
            {
              color: "#f2f2f2",
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
      ],
    };
    this.map = new google.maps.Map(
      this.mapElement.nativeElement,
      this.mapOptions
    );
    const shape = {
      coords: [1, 1, 1, 20, 18, 20, 18, 1],
      type: "poly",
    };
    this.marker = new google.maps.Marker({
      position: this.mapOptions.center,
      map: this.map,
      icon: {
        url: "../../../../assets/img/map-marker.svg",
      },
      draggable: true, // !this.currentLocation ? true : false,
      animation: google.maps.Animation.DROP,
      optimized: true,
      shape: shape,
    });

    var myoverlay = new google.maps.OverlayView();
    myoverlay.draw = function () {
      this.getPanes().markerLayer.id = "markerLayer";
    };
    myoverlay.setMap(this.map);

    this.map.addListener('click', (event) => {
      this.placeMarker(event.latLng);
    });
    
    if (!this.currentLocation) {
      this.getCurrentLocation();
    } else {
      this.marker.setPosition({ lat: this.currentLocation.coordinates[0], lng: this.currentLocation.coordinates[1] });
      this.map.setCenter({ lat: this.currentLocation.coordinates[0], lng: this.currentLocation.coordinates[1] }); // setCenter(center:GLatLng, zoom?:Number, type?:GMapType) | None
      this.map.setZoom(16);
    }
  }

  placeMarker(location) {
    this.marker.setPosition(location);
    this.location.emit({
      coordinates: [
        this.marker.getPosition().lat(),
        this.marker.getPosition().lng(),
      ],
    });
    const currentPosition = {
      lat: this.marker.getPosition().lat(),
      lng: this.marker.getPosition().lng(),
    };
    localStorage.setItem(
      "location",
      JSON.stringify({
        coordinates: [currentPosition.lat, currentPosition.lng],
      })
    );
  }

  getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(
      (resp) => {
        const currentPosition = {
          lat: resp.coords.latitude,
          lng: resp.coords.longitude,
        };
        localStorage.setItem(
          'location',
          JSON.stringify({
            coordinates: [currentPosition.lat, currentPosition.lng],
          })
        );
  
        this.map.setCenter(currentPosition);
        this.map.setZoom(16);
  
        this.marker.setPosition(currentPosition);
        this.map.addListener("click", (event) => {
          this.placeMarker(event.latLng);
        });
        this.location.emit({
          coordinates: [this.marker.getPosition().lat(), this.marker.getPosition().lng()],
        });
      },
      (error) => {
        // Handle the case where the user blocks location access
        console.error('Error getting current location:', error);
  
        // Provide a default location (e.g., Jeddah) if location access is blocked
        const defaultLocation = {
          lat: 24.774265,
          lng: 46.738586,
        };
  
        localStorage.setItem(
          'location',
          JSON.stringify({
            coordinates: [defaultLocation.lat, defaultLocation.lng],
          })
        );
        this.map.setCenter(defaultLocation);
        this.map.setZoom(8);
  
        this.marker.setPosition(defaultLocation);
        this.map.addListener("click", (event) => {
          this.placeMarker(event.latLng);
        });
        this.location.emit({
          coordinates: [this.marker.getPosition().lat(), this.marker.getPosition().lng()],
        });
      }
    );
  }
  
}
