import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { AuthService } from "../../services/auth.service";
@Component({
  selector: "app-supplier-requests",
  templateUrl: "./supplier-requests.component.html",
  styleUrls: ["./supplier-requests.component.scss"],
})
export class SupplierRequestsComponent implements OnInit {
  active = true;
  loadedAccountDetails = false;
  isUploadedImages = false;
  user: any;
  acceptedByAdminStatus = false;
  image: any;
  upImage: any;

  // @Output() createOfferClicked = new EventEmitter();
  // viewDetails = false;
  // select = false;
  // select2 = false;
  paging: any;
  page = 1;
  orders = [];

  confirmed = false;
  createOffer = true;

  loading: boolean = false;
  filtrationData: any = { orderTypeFix: "general", pageSize: 10, page: 1 };
  general: boolean = true;

  constructor(private shanab: ShanabService, private auth: AuthService) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.auth.currentUser.subscribe((data) => {
      if (data) {
        this.user = data;
        this.loadedAccountDetails = true;
        this.acceptedByAdminStatus = data.acceptedByAdminStatus;
        if (data.images.length > 0) {
          this.isUploadedImages = true;
        }

        if (this.acceptedByAdminStatus) {
          this.getAllRequests(null);
        }
      }
    });
  }

  segmentChanged() {
    this.general = !this.general;
    this.filtrationData.orderTypeFix = this.general ? "general" : "direct";
    this.getAllRequests(null);
    this.orders = [];
  }

  getAllRequests(event) {
    this.loading = true;
    if (event) {
      this.filtrationData.page = event;
    } else {
      this.filtrationData.page = 1;
      this.orders = [];
    }
    this.shanab.getAllRequests(this.filtrationData).subscribe(
      (res: any) => {
        if (res) {
          this.loading = false;
          this.orders = res.data;
          this.paging = res.paging;
        }
      },
      () => {
        this.loading = false;
      }
    );
  }

  chooseImage(event) {
    this.upImage = event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
    };
    myReader.readAsDataURL(this.upImage);
  }

  updateProfile() {
    this.shanab.uploadFile([this.upImage]).subscribe((res) => {
      this.shanab
        .updateSupplierProfile({ images: res.data })
        .subscribe((result: any) => {
          this.auth.currentUser.next(result.data);
          localStorage.setItem("user-data", result.data);
          localStorage.setItem("auth-role", result["data"]["role"][0]);
          localStorage.setItem("auth-name", result["data"]["name"]);

          this.isUploadedImages = true;
        });
    });
  }

  // selectType() {
  //   this.select = !this.select;
  // }

  // selectType2() {
  //   this.select2 = !this.select2;
  // }
  // viewDetailsClick() {
  //   this.viewDetails = !this.viewDetails;
  // }
  // createOfferClick() {
  //   this.createOfferClicked.emit();
  // }

  

  changePage(event) {
    
    if (event) {
      this.page = event;
    }
    this.getAllRequests(this.page);
  }
}
