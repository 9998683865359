import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpInitService } from "./http-init.service";

const TOKEN_KEY = "auth-token";
const ROLE_KEY = "auth-role";
const SUBSCRIPTION_KEY = "auth-subscriptionType";
const NAME_KEY = "auth-name";
const PREMIUM_KEY = "auth-isPremium";

export interface User {
  name: string;
  roles: string[];
}
@Injectable({
  providedIn: "root"
})
export class AuthService {
  currentUser = new BehaviorSubject(null);
  currentUserRoles = new BehaviorSubject(null);
  authenticationState = new BehaviorSubject(null);
  constructor(private httpInit: HttpInitService) {}

  login(loginData: any): Observable<any> {
    return this.httpInit.postRequest("otp/login", loginData);
  }

  checkCode(data: any): Observable<any> {
    return this.httpInit.postRequest("otp/sendCode", data);
  }

  checkUserRoles(data: any) {
    this.currentUserRoles.next(data.role);
  }

  logout() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ROLE_KEY);
    localStorage.removeItem(NAME_KEY);
    localStorage.removeItem(SUBSCRIPTION_KEY);
    localStorage.removeItem(PREMIUM_KEY);
    localStorage.removeItem("user-data");
  }

  isAuthenticated() {
    return this.authenticationState.value != undefined ? true : false;
  }

  isWorkshop() {
    return localStorage.getItem(ROLE_KEY) == "supplier" ? true : false;
  }
  isConsumer() {
    return localStorage.getItem(ROLE_KEY) == "consomer" ? true : false;
  }

  initUser() {
    if (localStorage.getItem(TOKEN_KEY)) {
      this.checkToken();
    }

    if (localStorage.getItem(ROLE_KEY)) {
      this.currentUserRoles.next(localStorage.getItem(ROLE_KEY));
    }
  }

  checkToken() {
    this.httpInit.getRequest("otp/checkToken").subscribe((res) => {
      if (res["status"] == false) {
        this.logout();
      } else {
        this.authenticationState.next(true);
      }
    });
  }

  getProfileData() {
    this.httpInit
      .getRequest("otp/frontend/currentuser")
      .subscribe((res: any) => {
        this.currentUser.next(Array.isArray(res.data) ? res.data[0] : res.data);
      });
  }

  hasRoles(roles: string[]): boolean {
    for (const oneRole of roles) {
      if (
        !this.currentUserRoles.value ||
        !this.currentUserRoles.value.roles.includes(oneRole)
      ) {
        return false;
      }
    }
    return true;
  }
}
