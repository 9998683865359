import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-place-direct-order-step2",
  templateUrl: "./place-direct-order-step2.component.html",
  styleUrls: ["./place-direct-order-step2.component.scss"],
})
export class PlaceDirectOrderStep2Component implements OnInit {
  @Input("supplierId") supplierId;
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  @Output() setType = new EventEmitter();
  shopTypes: any[] = [];
  selected: string;

  constructor(private shanabService: ShanabService) {}

  ngOnInit() {
    this.getOrderTypes();
  }

  getOrderTypes() {
    this.shanabService
      .getSupplierTypes({ supplierId: this.supplierId })
      .subscribe((res: any) => {
        if (res) {
          this.shopTypes = res.data;
        }
      });
  }

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    this.nextChange.emit({ type: this.selected });
  }

  selectType(i) {
    this.selected = this.shopTypes[i]._id;
    this.setType.emit(this.selected);
    this.shopTypes.forEach((item, index) => {
      if (i == index) {
        this.shopTypes[index].select = true;
      } else {
        this.shopTypes[index].select = false;
      }
    });
  }
}
