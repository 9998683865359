import { AddSellCarDetailsComponent } from "./frontEnd/pages/add-sell-car-details/add-sell-car-details.component";
import { SupplierShopServiceComponent } from "./frontEnd/pages/supplier-shop-service/supplier-shop-service.component";
import { PrivacyComponent } from "./frontEnd/pages/privacy/privacy.component";
import { TermsComponent } from "./frontEnd/pages/terms/terms.component";
import { OrderDetilsPageComponent } from "./frontEnd/pages/order-detils-page/order-detils-page.component";
import { HelpPageComponent } from "./frontEnd/pages/help-page/help-page.component";
import { VehiclesPageComponent } from "./frontEnd/pages/vehicles-page/vehicles-page.component";
import { ProfilePageComponent } from "./frontEnd/pages/profile-page/profile-page.component";
import { OurShopsPageComponent } from "./frontEnd/pages/our-shops-page/our-shops-page.component";
import { MyOrdersPageComponent } from "./frontEnd/pages/my-orders-page/my-orders-page.component";
import { PlaceOrderComponent } from "./frontEnd/pages/place-order/place-order.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./frontEnd/pages/home/home.component";
import { RegisterComponent } from "./frontEnd/pages/register/register.component";
import { PlaceDirectOrderPgaeComponent } from "./frontEnd/pages/place-direct-order-pgae/place-direct-order-pgae.component";
import { PageNotFoundComponent } from "./frontEnd/pages/page-not-found/page-not-found.component";
import { TicketsPageComponent } from "./frontEnd/pages/tickets-page/tickets-page.component";
import { NotificationPageComponent } from "./frontEnd/pages/notification-page/notification-page.component";
import { PayToShowShopsPageComponent } from "./frontEnd/pages/pay-to-show-shops-page/pay-to-show-shops-page.component";
// tslint:disable-next-line: max-line-length
import { OrderDetailsAfterPayShowComponent } from "./frontEnd/pages/order-details-after-pay-show/order-details-after-pay-show.component";
import { OfferAccepetPageComponent } from "./frontEnd/pages/offer-accepet-page/offer-accepet-page.component";
import { PayToTransportComponent } from "./frontEnd/pages/pay-to-transport/pay-to-transport.component";
import { SupplierRequestsComponent } from "./frontEnd/pages/supplier-requests/supplier-requests.component";
import { SupplierOffersComponent } from "./frontEnd/pages/supplier-offers/supplier-offers.component";
import { AddTicketComponent } from "./frontEnd/pages/add-ticket/add-ticket.component";
import { OurShopsDetailsComponent } from "./frontEnd/pages/our-shops-details/our-shops-details.component";
import { CreateOfferComponent2 } from "./frontEnd/pages/create-offer/create-offer.component";
import { ConsumerGuard } from "./frontEnd/services/consumer-guard.service";
import { AddVechileComponent } from "./frontEnd/pages/add-vechile/add-vechile.component";
import { WorkshopGuard } from "./frontEnd/services/workshop-guard.service";
import { RequestDetailsComponent } from "./frontEnd/pages/request-details/request-details.component";
import { SellYourCarComponent } from "./frontEnd/pages/sell-your-car/sell-your-car.component";
import { AddSellCarComponent } from "./frontEnd/pages/add-sell-car/add-sell-car.component";
import { SuplierBrandsComponent } from "./frontEnd/pages/suplier-brands/suplier-brands.component";
import { ReturnPolicyComponent } from "./frontEnd/pages/return-policy/return-policy.component";
import { ChangePasswordComponent } from "./frontEnd/pages/change-password/change-password.component";
import { EditProfileComponent } from "./frontEnd/pages/edit-profile/edit-profile.component";
import { ChatComponent } from "./frontEnd/pages/chat/chat.component";
import { ChatListingPage } from "./frontEnd/pages/chat-listing/chat-listing.component";
import { SearchCarsComponent } from "./frontEnd/pages/search-cars/search-cars.component";
//// 503529077
const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "verify/:token", component: HomeComponent },
  { path: "login", component: RegisterComponent },
  {
    path: "placeorder",
    canActivate: [ConsumerGuard],
    component: PlaceOrderComponent
  },
  {
    path: "chat-listing",
    canActivate: [ConsumerGuard],
    component: ChatListingPage
  },
  {
    path: "myorders",
    canActivate: [ConsumerGuard],
    component: MyOrdersPageComponent
  },
  {
    path: "ourshops",
    // canActivate: [ConsumerGuard],
    component: OurShopsPageComponent
  },
  {
    path: "place-direct-order", /////
    canActivate: [ConsumerGuard],
    component: PlaceDirectOrderPgaeComponent
  },
  {
    path: "order-details",
    canActivate: [ConsumerGuard],
    component: OrderDetilsPageComponent
  },
  {
    path: "placeorder",
    canActivate: [ConsumerGuard],
    component: PlaceOrderComponent
  },
  {
    path: "myorders",
    canActivate: [ConsumerGuard],
    component: MyOrdersPageComponent
  },

  {
    path: "place-direct-order/:shopId",
    canActivate: [ConsumerGuard],
    component: PlaceDirectOrderPgaeComponent
  },
  {
    path: "order-details/:orderId",
    canActivate: [ConsumerGuard],
    component: OrderDetilsPageComponent
  },
  {
    path: "profile",

    component: ProfilePageComponent
  },
  {
    path: "vehicles",
    canActivate: [ConsumerGuard],
    component: VehiclesPageComponent
  },
  {
    path: "change-password",
    canActivate: [ConsumerGuard],
    component: ChangePasswordComponent
  },
  {
    path: "change-passwordsub",
    canActivate: [WorkshopGuard],
    component: ChangePasswordComponent
  },
  {
    path: "edit-profilesub",
    canActivate: [WorkshopGuard],
    component: EditProfileComponent
  },
  {
    path: "edit-profile",
    canActivate: [ConsumerGuard],
    component: EditProfileComponent
  },
  {
    path: "add-vehicle",
    canActivate: [ConsumerGuard],
    component: AddVechileComponent
  },
  {
    path: "help",
    component: HelpPageComponent
  },
  {
    path: "tickets",

    component: TicketsPageComponent
  },
  {
    path: "Notifications",
    canActivate: [ConsumerGuard],
    component: NotificationPageComponent
  },
  {
    path: "Pay-to-show-shops/:orderId",
    canActivate: [ConsumerGuard],
    component: PayToShowShopsPageComponent
  },
  {
    path: "order-offers/:orderId",
    canActivate: [ConsumerGuard],
    component: OrderDetailsAfterPayShowComponent
  },
  {
    path: "offer-accept/:offerId",
    canActivate: [ConsumerGuard],
    component: OfferAccepetPageComponent
  },
  {
    path: "pay-to-transport/:offerId",
    canActivate: [ConsumerGuard],
    component: PayToTransportComponent
  },
  {
    path: "shop-services",
    canActivate: [WorkshopGuard],
    component: SupplierShopServiceComponent
  },
  {
    path: "supplier-brands",
    canActivate: [WorkshopGuard],
    component: SuplierBrandsComponent
  },
  {
    path: "requestes",
    canActivate: [WorkshopGuard],
    component: SupplierRequestsComponent
  },
  {
    path: "request-details/:id",
    canActivate: [WorkshopGuard],
    component: RequestDetailsComponent
  },
  {
    path: "offers",
    canActivate: [WorkshopGuard],
    component: SupplierOffersComponent
  },
  {
    path: "add-ticket",
    canActivate: [WorkshopGuard],
    component: AddTicketComponent
  },
  {
    path: "shop/:id",
    // canActivate: [WorkshopGuard],
    component: OurShopsDetailsComponent
  },
  {
    path: "create-offer/:id",
    canActivate: [WorkshopGuard],
    component: CreateOfferComponent2
  },
  { path: "terms-and-conditions", component: TermsComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "return-policy", component: ReturnPolicyComponent },
  { path: "sell-your-car", component: SellYourCarComponent },
  { path: "add-sell-car", component: AddSellCarComponent },
  { path: "add-sell-car-details/:id", component: AddSellCarDetailsComponent },
  { path: "chat/:chatId", component: ChatComponent },
  { path: "search", component: SearchCarsComponent },
  { path: "**", component: PageNotFoundComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
