import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/frontEnd/services/auth.service";
import { LanguageService } from "src/app/frontEnd/services/language.service";

@Component({
  selector: "app-header-sign-in",
  templateUrl: "./header-sign-in.component.html",
  styleUrls: ["./header-sign-in.component.scss"],
})
export class HeaderSignInComponent implements OnInit {
  lang: string;
  username: string;
  selected: any = {};
  // tslint:disable-next-line: no-input-rename
  @Input("text") text = "";
  // tslint:disable-next-line: no-input-rename
  @Input("btnProfile") btnProfile = false;
  // tslint:disable-next-line: no-input-rename
  @Input("btnRegister") btnRegister = false;
  @Input("notLoggedIn") notLoggedIn = false;
  role: string;
  isAuth: boolean = !!localStorage.getItem("auth-token");

  constructor(
    private languageService: LanguageService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getLang();
    this.authService.currentUser.subscribe((res) => {
      if (res) {
        this.username = res.name;
        this.role = res.role[0];
        this.isAuth = !!localStorage.getItem('auth-token');
      }
    });

    this.languageService.selectedLanguage.subscribe((res) => {
      if (res) {
        this.selected = res;
      }
    });
  }
  changeLang() {
    if (this.selected == "en") {
      this.lang = "AR";
      this.languageService.setLanguage("ar");
    } else if (this.selected == "ar") {
      this.lang = "EN";
      this.languageService.setLanguage("en");
    } else {
      this.lang = "EN";
      this.languageService.setLanguage("en");
    }
  }

  logout() {
    this.authService.logout();
  }

  getLang() {
    if (localStorage.getItem("lang") === "en") {
      this.lang = "AR";
    } else {
      this.lang = "EN";
    }
  }

  goTo(id) {
    this.router.navigateByUrl("/");
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 100);
  }
}
