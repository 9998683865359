import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase/app";
import { switchMap, map } from "rxjs/operators";
import { HttpInitService } from "src/app/frontEnd/services/http-init.service";
import { Observable } from "rxjs";

export interface User {
  uid: string;
  email: string;
}

export interface Message {
  createdAt: firebase.default.firestore.FieldValue;
  id: string;
  from: string;
  msg: string;
  fromName: string;
  myMsg: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ChatService {
  currentUser: User = null;
  messages: any = [];
  user = localStorage.getItem('user-data') ? JSON.parse(localStorage.getItem('user-data')) : null;


  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private initService: HttpInitService
  ) {
    this.afAuth.onAuthStateChanged((user) => {
      if (!user) {
        try {
          this.afAuth.signInAnonymously();
        } catch ({ message }) {
          alert(message);
        }
      }
    });
  }

  addChatMessage(msg, roomId) {
    return this.afs.collection("messages").doc(roomId).collection("room").add({
      msg,
      from: this.user._id,
      fromName: this.user.name,
      createdAt: firebase.default.firestore.FieldValue.serverTimestamp(),
    });
  }
 
  getChatMessages(chatId) {
    let users = [];
    return this.getUsers().pipe(
      switchMap((res) => {
        users = res;
        return this.afs
          .collection("messages")
          .doc(chatId)
          .collection("room", (ref) => ref.orderBy("createdAt"))
          .valueChanges({ idField: "id" }) as Observable<Message[]>;
      }),
      map((messages) => {
        // Get the real name for each user
        for (let m of messages) {
          m.myMsg = this.user._id === m.from;
        }
        return messages;
      })
    );
  }

  private getUsers() {
    return this.afs
      .collection("users")
      .valueChanges({ idField: "uid" }) as Observable<User[]>;
  }

  createChatRoom(data): Observable<any> {
    return this.initService.postRequest("fireStoreChat/createChat", data);
  }
  blockChatRoom(roomId) {
    return this.initService.putRequest("fireStoreChat/updateChat/"+roomId,{}); 
  }


  fetchChatRoom(): Observable<any> {
    return this.initService.getRequest("fireStoreChat/getMyChats");
  }
}
