import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-my-orders-segment",
  templateUrl: "./my-orders-segment.component.html",
  styleUrls: ["./my-orders-segment.component.scss"],
})
export class MyOrdersSegmentComponent implements OnInit {
  @Output() changeSegment = new EventEmitter();
  selected = true;
  tab1: string = "";
  tab2: string = "";
  tab3: string = "";
  tabName: string = "";
  constructor() {}

  ngOnInit() {}

  onClick(event) {
    console.log(event.target.innerText);
    this.tabName = event.target.innerText;
    if (this.tabName == "General Order" || this.tabName == "الطلبات العامة") {
      this.tab1 = "active";
      this.tab2 = "unactive";
      this.tab3 = "unactive";
    }
    if (this.tabName == "Direct Order" || this.tabName == "طلب مباشر") {
      this.tab1 = "unactive";
      this.tab2 = "active";
      this.tab3 = "unactive";
    }
    if (this.tabName == "Shanab Order" || this.tabName == "طلب شنب") {
      this.tab1 = "unactive";
      this.tab2 = "unactive";
      this.tab3 = "active";
    }
    this.changeSegment.emit(event.target.innerText);
  }
}
