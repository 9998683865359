import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-order-offers-after-pay-card",
  templateUrl: "./order-offers-after-pay-card.component.html",
  styleUrls: ["./order-offers-after-pay-card.component.scss"],
})
export class OrderOffersAfterPayCardComponent implements OnInit {
  @Input("orderId") orderId: string;
  offers: any[] = [];
  order: any = {
    myCar: { brand: {}, model: {} },
    orderType: { images: [{ url: "" }], name: {} },
    statuses: [],
  };
  loading = false;

  constructor(private shanabService: ShanabService, private router: Router) {}

  ngOnInit() {
    this.getAllOffers();
  }

  goMap(data) {
    window.open(
      `http://www.google.com/maps/place/${data.supplier.location.coordinates[0]},${data.supplier.location.coordinates[1]}`
    );
  }

  getAllOffers() {
    this.loading = true;
    this.shanabService.getAllOffers(this.orderId).subscribe(
      (res: any) => {
        if (res) {
          this.offers = res.data;
          this.loading = false;
        }
      },
      () => {
        this.loading = false;
      }
    );
  }

  accept(id) {
    this.shanabService.acceptOffer(id).subscribe(() => {
      this.router.navigate(["/offer-accept/" + id]);
    });
  }
}
