import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-order-details-after-pay-show",
  templateUrl: "./order-details-after-pay-show.component.html",
  styleUrls: ["./order-details-after-pay-show.component.scss"],
})
export class OrderDetailsAfterPayShowComponent implements OnInit {
  orderId: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.orderId = this.route.snapshot.params.orderId;
  }
}
