import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-vehicles-add-car",
  templateUrl: "./vehicles-add-car.component.html",
  styleUrls: ["./vehicles-add-car.component.scss"],
})
export class VehiclesAddCarComponent implements OnInit {
  @Output() btnAddClicked = new EventEmitter();
  // selectcar = false;
  // selectcar2 = false;

  models = [];
  years = [
    "1990",
    "1991",
    "1992",
    "1993",
    "1994",
    "1995",
    "1996",
    "1997",
    "1998",
    "1999",
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
  ];
  brands = [];
  colors: any[] = [
    { color: "#ffffff", selected: false },
    { color: "#f4f4f4", selected: false },
    { color: "#999999", selected: false },
    { color: "#444444", selected: false },
    { color: "#000000", selected: false },
    { color: "#8e44ad", selected: false },
    { color: "#2980b9", selected: false },
    { color: "#c0392b", selected: false },
    { color: "#d35400", selected: false },
    { color: "#f39c12", selected: false },
    { color: "#27ae60", selected: false },
    { color: "#16a085", selected: false },
    { color: "#2c3e50", selected: false },
  ];
  form: FormGroup;
  constructor(private shanab: ShanabService, private router: Router) {}

  ngOnInit() {
    this.form = new FormGroup({
      brand: new FormControl("", [Validators.required]),
      model: new FormControl("", [Validators.required]),
      year: new FormControl("", [Validators.required]),
      color: new FormControl("", [Validators.required]),
    });
    this.shanab.getCarModels().subscribe((data) => {
      this.brands = data.data;
    });
  }

  changeBrand() {
    this.models = this.brands.find((e) => e._id == this.form.get("brand").value)
      ? this.brands.find((e) => e._id == this.form.get("brand").value).children
      : [];
  }

  add() {
    let body = { ...this.form.value };
    delete body.brand;
    this.shanab.addNewCar(body).subscribe((data) => {
      this.router.navigateByUrl("/vehicles");
    });
  }

  // goToMyCar() {
  //   this.btnAddClicked.emit();
  // }
  // selectCar() {
  //   if (this.selectcar2 === false) {
  //     this.selectcar = !this.selectcar;
  //   } else {
  //     this.selectcar = !!this.selectcar;
  //   }
  // }

  // selectCar2() {
  //   if (this.selectcar === false) {
  //     this.selectcar2 = !this.selectcar2;
  //   } else {
  //     this.selectcar2 = !!this.selectcar2;
  //   }
  // }
}
