import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-my-orders-page",
  templateUrl: "./my-orders-page.component.html",
  styleUrls: ["./my-orders-page.component.scss"]
})
export class MyOrdersPageComponent implements OnInit {
  general = true;
  orders: any[] = [];
  filtrationData: any = { orderTypeFix: "general", pageSize: 10, page: 1 };
  paging: any = {};
  loading: boolean = false;
  tabName: any = "";

  constructor(private shanabService: ShanabService) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.getAllOrders(null);
  }

  segmentChanged($event) {
    this.tabName = $event;
    if (this.tabName == "General Order" || this.tabName == "الطلبات العامة") {
      this.filtrationData.orderTypeFix = "general";
    } else if (this.tabName == "Direct Order" || this.tabName == "طلب مباشر") {
      this.filtrationData.orderTypeFix = "direct";
    } else if (this.tabName == "Shanab Order" || this.tabName == "طلب شنب") {
      this.filtrationData.orderTypeFix = "shanab";
    }
    this.getAllOrders(null);
    this.orders = [];
  }

  getAllOrders(event) {
    this.loading = true;
    if (event) {
      this.filtrationData.page = event;
    } else {
      this.filtrationData.page = 1;
      this.orders = [];
    }
    this.shanabService.getAllOrders(this.filtrationData).subscribe(
      (res: any) => {
        if (res) {
          this.loading = false;
          this.orders = res.data;
          this.paging = res.paging;
        }
      },
      () => {
        this.loading = false;
      }
    );
  }
}
