import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShanabService } from 'src/app/services/shanab.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private route: ActivatedRoute, private shanab: ShanabService, private router: Router) {}

  ngOnInit() {
    let token = this.route.snapshot.params.token;
    if (token) {
      this.shanab.verifyAccount(token).subscribe((data) => {
        this.router.navigateByUrl('/login');
      });
    }
  }
}
