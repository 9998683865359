import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-orde-offers-card-value",
  templateUrl: "./orde-offers-card-value.component.html",
  styleUrls: ["./orde-offers-card-value.component.scss"],
})
export class OrdeOffersCardValueComponent implements OnInit {
  @Output() shopsInfoClicked = new EventEmitter();
  offers: any[] = [];
  @Input("order") order: any;
  loading = false;

  constructor(private shanabService: ShanabService) {}

  ngOnInit() {
    this.getAllOffers();
  }

  clickShopsInfo() {
    this.shopsInfoClicked.emit();
  }

  getAllOffers() {
    this.loading = true;
    this.shanabService.getAllOffers(this.order ? this.order._id : '').subscribe(
      (res: any) => {
        if (res) {
          this.offers = res.data;
          this.loading = false;
          setTimeout(() => {
            document.getElementById("offers-list").scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }, 50);
        }
      },
      () => {
        this.loading = false;
      }
    );
  }
}
