import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  NgZone,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryOptions,
} from "ngx-gallery";
import { ShanabService } from "src/app/services/shanab.service";
import { AuthService } from "../../services/auth.service";
import { ChatService } from "src/app/frontEnd/services/chat.service";
@Component({
  selector: "app-add-sell-car-details",
  templateUrl: "./add-sell-car-details.component.html",
  styleUrls: ["./add-sell-car-details.component.scss"],
})
export class AddSellCarDetailsComponent implements OnInit {
  id = this.route.snapshot.params.id;
  comment = "";
  bid = "";
  comments = [];
  checking: boolean = false;
  car: any;
  paging: any;
  user = JSON.parse(localStorage.getItem('user-data'));
  activeTab: string = "";

  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event: Event) {
    this.zone.run(() => {
      this.setActiveTab();
    });
    const jumplinksElement = this.el.nativeElement.querySelector("#add-stick");
    if (window.scrollY >= 470) {
      this.renderer.addClass(jumplinksElement, "jump-stick");
    } else {
      this.renderer.removeClass(jumplinksElement, "jump-stick");
    }
  }

  constructor(
    private shanab: ShanabService,
    private chat: ChatService,
    private route: ActivatedRoute,
    private router: Router,
    private el: ElementRef,
    private zone: NgZone,
    private renderer: Renderer2
  ) {}

  scrollToSection(sectionId: string): void {
    const sectionElement = this.el.nativeElement.querySelector(`#${sectionId}`);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  setActiveTab() {
    const sections = this.el.nativeElement.querySelectorAll("section[data-id]");
    let found = false;

    sections.forEach((section: HTMLElement) => {
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.top <= window.innerHeight && !found) {
        this.activeTab = section.id;
        found = true;
      }
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    // this.setActiveTab();
    this.galleryOptions = [
      {
        width: "100%",

        height: "400px",
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: "100%",
        height: "300px",
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
      },
    ];

    this.shanab.getCarDetails(this.id).subscribe((data: any) => {
      this.car = data.data.length > 0 ? data.data[0] : null;

      this.car.images.forEach((img) => {
        this.galleryImages.push({
          small: img.url,
          medium: img.url,
          big: img.url,
        });
      });
    });

    this.getAllComments();
  }

  getAllComments() {
    this.shanab.getCarComments(this.id, 1).subscribe((data: any) => {
      this.comments = data.data;
      this.paging = data.paging;
    });
  }

  addComment() {
    if (!this.comment) return;
    this.shanab
      .addCommentToCar(this.id, { text: this.comment, bid: this.bid })
      .subscribe((data) => {
        this.comment = "";
        this.bid = "";
        this.getAllComments();
      });
  }

  openImg(img) {
    window.open(img);
  }

  cancle() {
    this.shanab.cancelSellCar(this.id).subscribe((data) => {
      this.router.navigateByUrl("/sell-your-car");
    });
  }

  addReplay(comment) {
    const replay = document.getElementById(comment._id) as any;
    if (!replay) return;

    this.shanab
      .addReplyToSellCar(comment._id, { reply: replay.value })
      .subscribe((data) => {
        replay.value = "";
        this.getAllComments();
      });
  }

  loadMore() {
    this.paging.currentPage = this.paging.currentPage + 1;
    this.shanab
      .getCarComments(this.id, this.paging.currentPage)
      .subscribe((data: any) => {
        this.comments = [...this.comments, ...data.data];

        this.paging = data.paging;
      });
  }

  openChat() {
    this.checking = true;
    console.log(this.user,'user')
    console.log(this.chat,'asdas')
    // debugger
    this.router.navigateByUrl(`/chat/${this.id}`);
    // this.chat
    //   .createChatRoom({
    //     user: userId,
    //     sellCarId: this.id,
    //     image: this.car.images[0].url,
    //     carName: this.car.brand.name + " " + this.car.model.name,
    //     price: this.car.price,
    //   })
    //   .subscribe(
    //     (res) => {
    //       this.checking = false;
    //       this.router.navigateByUrl(`/chat/${this.id}`);
    //     },
    //     (err) => {
    //       this.checking = false;
    //     }
    //   );
  }
  @HostListener("window:popstate", ["$event"])
  onBrowserBackBtnClose(event: Event) {
    console.log("back button pressed");
    event.preventDefault();
    this.router.navigate(["/"], { replaceUrl: true });
  }
}
