import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "./frontEnd/services/language.service";
import { AuthService } from "./frontEnd/services/auth.service";
import { Router } from "@angular/router";
import { ShanabService } from "./services/shanab.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private langService: LanguageService,
    private authService: AuthService,
    private router: Router,
    private settingService: ShanabService
  ) {
    // const lang = localStorage.getItem('lang');
    // if (lang === 'en') {
    //   translate.setDefaultLang('en');
    //   document.dir = 'ltr';
    // } else if (lang === 'ar') {
    //   translate.setDefaultLang('ar');
    //   document.dir = 'rtl';
    // } else {
    //   translate.setDefaultLang('en');
    //   localStorage.setItem('lang', 'en');
    //   document.dir = 'ltr';
    // }

    this.authService.initUser();
    this.settingService.getSettings();

    if (localStorage.getItem("auth-token")) {
      this.authService.getProfileData();
    }
    setTimeout(() => {
      this.checkIfInitialize();
    }, 500);
  }

  ngOnInit() {
    this.langService.setInitialAppLanguage();

    // setInterval(() => {
    //   console.clear();
    // }, 5000);
  }

  checkIfInitialize() {
    this.authService.authenticationState.subscribe((authState) => {
      if (authState === null) {
        const isAuthenticated = this.authService.isAuthenticated();
        this.authService.authenticationState.next(isAuthenticated);
      } else if (authState === true) {
        if (this.router.url === "/login" || this.router.url === "/") {
          if (localStorage.getItem("auth-role") == "consomer") {
            this.router.navigate(["/"]);
          } else {
            this.router.navigate(["requestes"]);
          }
        } else {
          this.router.navigate([this.router.url]);
        }
      } else if (authState == false) {
        if (this.router.url === "/login") {
          this.router.navigate(["login"]);
        } else if (this.router.url) {
          this.router.navigate([this.router.url]);
        } else {
          this.router.navigate(["/"]);
        }
      }
    });
  }
}
