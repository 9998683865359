import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-notification-page",
  templateUrl: "./notification-page.component.html",
  styleUrls: ["./notification-page.component.scss"],
})
export class NotificationPageComponent implements OnInit {
  notifications: any[] = [];
  paging: any = {};
  filtrationData: any = { pageSize: 10, page: 1 };
  loading = false;

  constructor(private shanabService: ShanabService) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.getAllNotifications(null);
  }

  getAllNotifications(event) {
    this.loading = true;
    if (event) {
      this.filtrationData.page = event;
    }
    this.shanabService.getAllNotifications(this.filtrationData).subscribe(
      (res: any) => {
        this.loading = false;
        if (res) {
          this.notifications = res.data;
          this.paging = res.paging;
        }
      },
      () => {
        this.loading = false;
      }
    );
  }
}
