import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-mycar-card",
  templateUrl: "./mycar-card.component.html",
  styleUrls: ["./mycar-card.component.scss"],
})
export class MycarCardComponent implements OnInit {
  cars = [];
  constructor(private shanab: ShanabService) {}

  ngOnInit() {
    this.shanab.getMyCars().subscribe((data) => {
      this.cars = data.data;
    });
  }
}
