import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-profile-buttons",
  templateUrl: "./profile-buttons.component.html",
  styleUrls: ["./profile-buttons.component.scss"]
})
export class ProfileButtonsComponent implements OnInit {
  @Output() buttonClickedVehicle = new EventEmitter();
  @Output() buttonClickedHelp = new EventEmitter();
  @Output() buttonClickedShop = new EventEmitter();
  @Input("role") role = "";
  emailVerified = false;
  rol = "";
  constructor(private router: Router, private auth: AuthService) {}

  ngOnInit() {
    this.auth.currentUser.subscribe((data) => {
      if (data) {
        this.emailVerified = data.emailVerified;
        this.rol = data.role[0];
      }
    });
  }

  buttonClickVehicle() {
    this.buttonClickedVehicle.emit();
  }

  buttonClickHelp() {
    this.buttonClickedHelp.emit();
  }

  buttonClickShopServise() {
    this.buttonClickedShop.emit();
  }

  changePassword() {
    if (this.rol == "consomer") {
      this.router.navigateByUrl("/change-password");
    } else {
      this.router.navigateByUrl("/change-passwordsub");
    }
  }
  editProfile() {
    if (this.rol === "consomer") {
      this.router.navigateByUrl("/edit-profile");
    } else {
      this.router.navigateByUrl("/edit-profilesub");
    }
    // this.router.navigateByUrl('/edit-profilesub');
  }
}
