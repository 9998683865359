import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-back-next-buttons",
  templateUrl: "./back-next-buttons.component.html",
  styleUrls: ["./back-next-buttons.component.scss"],
})
export class BackNextButtonsComponent implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  // tslint:disable-next-line: no-input-rename
  @Input("text") text = "Next";
  showValue: string;

  constructor() {}

  ngOnInit() {}

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    this.nextChange.emit();
  }

  disableNextButton(show: string) {
    this.showValue = show;
  }
}
