import { Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NavParamsService } from "src/app/frontEnd/services/nav-params.service";

@Component({
  selector: "app-my-orders-card",
  templateUrl: "./my-orders-card.component.html",
  styleUrls: ["./my-orders-card.component.scss"],
})
export class MyOrdersCardComponent implements OnInit {
  @Input("data") data: any = {};
  confirmd = false;
  constructor(private router: Router, private navParams: NavParamsService) {}

  ngOnInit() {}

  orderDetailsClicked() {
    // this.orderDetails.emit();
    this.navParams.setValue({ order: this.data });

    if (!this.data.paiedSuccess) {
      this.router.navigate(["order-details/" + this.data._id]);
    } else {
      this.router.navigate(["order-offers/" + this.data._id]);
    }
  }
}
