import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-buttons',
  templateUrl: './help-buttons.component.html',
  styleUrls: ['./help-buttons.component.scss']
})
export class HelpButtonsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  showTeckits() {
    this.router.navigate(['/tickets']);
  }
}
