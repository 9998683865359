import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-supplier-register-step5',
  templateUrl: './supplier-register-step5.component.html',
  styleUrls: ['./supplier-register-step5.component.scss']
})
export class SupplierRegisterStep5Component implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    this.nextChange.emit();
  }
}
