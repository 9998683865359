import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
  step = [];
  isSupplier = false;
  mobileForm: FormGroup;
  role: string;
  loginCode: string;
  code: string;
  supplierData: object;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private shanabService: ShanabService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.initForm();
  }

  initForm() {
    this.mobileForm = this.fb.group({
      phone: ["", [Validators.required, Validators.minLength(9)]],
      role: [this.role],
      type: ["phone"],
      email: [""],
      password: [""],
      acceptTermsAndConditions: [true, Validators.required]
    });
  }

  checkCode() {
    const data = {
      phone: this.mobileForm.get("phone").value,
      loginCode: this.loginCode
    };
    this.authService.checkCode(data).subscribe(
      (res: any) => {
        if (res && res.status == true) {
          this.loginCode = "";
          if (res["token"]) {
            this.authService.currentUser.next(res["data"]);
            localStorage.setItem("auth-token", res["token"]);
            localStorage.setItem("auth-role", res["data"]["role"][0]);
            localStorage.setItem("auth-name", res["data"]["name"]);
            localStorage.setItem("user-data", JSON.stringify(res.data));
            localStorage.setItem("auth-isPremium", res["isPremium"]);
            localStorage.setItem(
              "auth-subscriptionType",
              res["subscriptionType"]
            );

            this.authService.checkUserRoles(res);
          }
          if (res.data.name) {
            this.authService.authenticationState.next(true);
          } else {
            this.nextClicked();
          }
        }
      },
      (err) => {}
    );
  }

  setPhone(data) {
    this.mobileForm.get("phone").setValue(data.phoneNumber);
    this.mobileForm.get("type").setValue(data.type);
    this.mobileForm.get("email").setValue(data.email);
    this.mobileForm.get("password").setValue(data.password);

    this.login();
  }

  setCode(code) {
    this.loginCode = code;
    if (this.loginCode.length == 6) {
      this.checkCode();
    }
  }

  login() {
    let { type, phone, email, password } = this.mobileForm.value;

    if (type == "phone" && !phone) {
      return;
    }

    if (type == "email" && (!email || !password)) {
      return;
    }

    this.authService.login(this.mobileForm.value).subscribe(
      (res: any) => {
        if (res.status == true) {
          if (this.mobileForm.get("type").value == "email") {
            this.loginCode = "";
            if (res["token"]) {
              this.authService.currentUser.next(res["data"]);
              localStorage.setItem("auth-token", res["token"]);
              localStorage.setItem("auth-role", res["data"]["role"][0]);
              localStorage.setItem("auth-name", res["data"]["name"]);
              localStorage.setItem("user-data", JSON.stringify(res.data));
              localStorage.setItem("auth-isPremium", res["isPremium"]);
              localStorage.setItem(
                "auth-subscriptionType",
                res["subscriptionType"]
              );
              this.authService.checkUserRoles(res);
              if (res.data.name) {
                this.authService.authenticationState.next(true);
              } else {
                this.nextClicked();
              }
            }
          } else {
            this.nextClicked();
          }
        }
      },
      (err) => {}
    );
  }

  carOwnerRegister() {
    this.mobileForm.get("role").setValue("consomer");
    this.step.push(1);
    this.isSupplier = false;
  }

  supplierRegister() {
    this.mobileForm.get("role").setValue("supplier");

    this.step.push(1);
    this.isSupplier = true;
  }

  nextClicked(e?) {
    if (e) {
      this.supplierData = { ...e };
    }
    this.step.push(1);
  }

  backValueChanged() {
    this.step.pop();
  }

  lastClicked(img) {
    this.shanabService.uploadFile([img]).subscribe((res) => {
      const profileData = {
        ...this.supplierData,
        coordinates:
          (localStorage.getItem("location") &&
            JSON.parse(localStorage.getItem("location")).coordinates) ||
          null,
        images: res.data
      };
      this.shanabService
        .updateSupplierProfile(profileData)
        .subscribe((result: any) => {
          this.authService.currentUser.next(result.data);
          localStorage.setItem("user-data", JSON.stringify(result.data));

          localStorage.setItem("auth-role", result["data"]["role"][0]);
          localStorage.setItem("auth-name", result["data"]["name"]);
          this.authService.authenticationState.next(true);
        });
    });
  }
}
