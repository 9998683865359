import { Injectable } from "@angular/core";
import { HttpInitService } from "../frontEnd/services/http-init.service";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class ShanabService {
  settings = new BehaviorSubject(null);
  constructor(private initService: HttpInitService) {}

  getSettings() {
    this.initService.getRequest(`settings/frontend`).subscribe((data: any) => {
      this.settings.next(data.settings);
    });
  }

  uploadFile(file: any): Observable<any> {
    return this.initService.uploadRequest("upload/images/", file);
  }

  addOrder(data): Observable<any> {
    return this.initService.postRequest("order/frontend/owner/general", data);
  }

  addDirectOrder(data): Observable<any> {
    return this.initService.postRequest("order/frontend/owner/direct", data);
  }

  sendMessage(body): Observable<any> {
    return this.initService.postRequest(`contactus`, body);
  }
  sendHelp(body): Observable<any> {
    return this.initService.postRequest(`help/frontend`, body);
  }

  getAllSuppliers(body): Observable<any> {
    return this.initService.postRequest(`otp/frontend/suppliers`, body, true);
  }
  getCarModels(): Observable<any> {
    return this.initService.getRequest(`carModel/getTree`);
  }
  addNewCar(body): Observable<any> {
    return this.initService.postRequest(`myCar/addMyCar`, body);
  }
  getMyCars(): Observable<any> {
    return this.initService.getRequest(`myCar/getAllMyCarsForApp`);
  }
  getHelp(): Observable<any> {
    return this.initService.getRequest(`help/frontend`);
  }

  getAllTypes(serviceType): Observable<any> {
    return this.initService.getRequest(
      serviceType
        ? `orderType/frontend/types?serviceType=${serviceType}`
        : "orderType/frontend/types"
    );
  }

  getSupplierTypes(params): Observable<any> {
    return this.initService.getWithParamsRequest(
      "orderType/frontend/types",
      params
    );
  }

  getAllCars(): Observable<any> {
    return this.initService.getRequest("myCar/getAllMyCarsForApp");
  }
  getAllCities(): Observable<any> {
    return this.initService.getRequest("city/frontend");
  }
  getAllOrderTypes(): Observable<any> {
    return this.initService.getRequest("orderType/frontend/types");
  }
  getOurShops(page, searchWord, body, limit = 10): Observable<any> {
    return this.initService.postRequest(
      `otp/frontend/suppliers?searchWord=${searchWord}&page=${page}&limit=${limit}`,
      body,
      true
    );
  }
  getRandomShops(): Observable<any> {
    return this.initService.getRequest(`otp/frontend/suppliers/random`);
  }
  getRandomCars(): Observable<any> {
    return this.initService.getRequest(`sellCar/getAllSellCarsRandom`);
  }

  getOffer(id): Observable<any> {
    return this.initService.getRequest(
      `offer/frontend/owner/${id}/offerdetails`
    );
  }

  getOneShop(id): Observable<any> {
    return this.initService.getRequest(`otp/supplier/${id}`);
  }

  updateSupplierProfile(data): Observable<any> {
    return this.initService.putRequest("otp/updateProfile/supplier", data);
  }

  updateProfile(data): Observable<any> {
    return this.initService.putRequest("otp/updateProfile", data);
  }

  getAllNotifications(data?): Observable<any> {
    return this.initService.getWithParamsRequest(
      "notification/user/myNotifications",
      data
    );
  }

  getAllOffers(id: string, data?): Observable<any> {
    return this.initService.getWithParamsRequest(
      "offer/frontend/owner/" + id,
      data
    );
  }

  acceptOffer(id: string): Observable<any> {
    return this.initService.postRequest(
      "offer/frontend/owner/" + id + "/accept",
      {}
    );
  }

  addRate(data): Observable<any> {
    return this.initService.postRequest("rate/frontend", data);
  }

  getPaymentDetails(): Observable<any> {
    return this.initService.getRequest("payment/validatePayment");
  }

  pay(id: any): Observable<any> {
    return this.initService.postRequest(
      "offer/payment/" + id + "/website",
      {},
      true
    );
  }

  getTransportPaymentDetails(location: any): Observable<any> {
    return this.initService.postRequest(
      "section/frontend/point/website",
      location,
      true
    );
  }

  getAllOrders(data): Observable<any> {
    return this.initService.getWithParamsRequest("order/frontend/owner", data);
  }

  getOrder(id): Observable<any> {
    return this.initService.getRequest(`order/frontend/owner/${id}`);
  }

  cancelOrder(data): Observable<any> {
    return this.initService.postRequest("order/frontend/owner/cancel", data);
  }

  getAllRequests(data: any): Observable<any> {
    return this.initService.getWithParamsRequest(
      "order/frontend/supplier",
      data
    );
  }

  addRequest(data: any): Observable<any> {
    return this.initService.postRequest("offer/frontend/supplier", data);
  }
  addOfferForOrder(data: any): Observable<any> {
    return this.initService.postRequest("offer/frontend/supplier", data);
  }
  goToNextStatus(id): Observable<any> {
    return this.initService.putRequest("offer/frontend/nextstep/" + id, {});
  }
  getOneOrder(id): Observable<any> {
    return this.initService.getRequest("order/frontend/supplier/" + id);
  }
  getOrderStatus(): Observable<any> {
    return this.initService.getRequest("orderstatus/frontend");
  }
  getSupplierComments(id): Observable<any> {
    return this.initService.getRequest("otp/supplier/" + id + "/website");
  }
  getOffersForSupplier(data): Observable<any> {
    return this.initService.getWithParamsRequest(
      "offer/frontend/supplier",
      data
    );
  }
  getAllSellCars(params): Observable<any> {
    return this.initService.getRequest(
      `sellCar/getAllSellCars/`,
      params
      // ?city=${city}&user=${
      //   user ? user : ""
      // }&page=${page}&pageSize=${pageSize}
    );
  }

  // city, user, page, pageSize
  getMySellCars(params): Observable<any> {
    // let params = new HttpParams()
    return this.initService.getRequest(
      `sellCar/getAllSellCars/`,
      params
      // ?city=${city}&user=${
      //   user ? user : ""
      // }&page=${page}&pageSize=${pageSize}
    );
  }
  addSellCar(body): Observable<any> {
    return this.initService.postRequest(`sellCar/addSellCar`, body);
  }
  editPassword(body): Observable<any> {
    return this.initService.putRequest(`otp/updatePassword`, body);
  }

  verifyAccount(token): Observable<any> {
    return this.initService.getRequest(`otp/verify/?token=${token}`);
  }

  getCarDetails(id) {
    return this.initService.getRequest(`sellCar/getAllSellCars/?id=${id}`);
  }
  getCarComments(id, page) {
    return this.initService.getRequest(
      `sellCar/getComments/${id}?page=${page}&pageSize=${10}`
    );
  }
  addCommentToCar(id, body) {
    return this.initService.postRequest(`sellCar/addComment/${id}`, body);
  }
  cancelSellCar(id) {
    return this.initService.deleteRequest(`sellCar/cancelSellCar/${id}`);
  }
  addReplyToSellCar(id, body) {
    return this.initService.postRequest(`sellCar/addReply/${id}`, body);
  }
}
