import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-our-shops-home",
  templateUrl: "./our-shops-home.component.html",
  styleUrls: ["./our-shops-home.component.scss"],
})
export class OurShopsHomeComponent implements OnInit {
  shops = [];
  cars = [];
  constructor(private shanab: ShanabService) {}

  ngOnInit() {
    this.shanab.getRandomShops().subscribe((data) => {
      this.shops = data.data.slice(0, 4);
    });
    this.shanab.getRandomCars().subscribe((data) => {
      this.cars = data.data.slice(0, 4);
    });
  }
}
