import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-how-work-home",
  templateUrl: "./how-work-home.component.html",
  styleUrls: ["./how-work-home.component.scss"],
})
export class HowWorkHomeComponent implements OnInit {
  setting: any;
  constructor(private settingService: ShanabService) {}

  ngOnInit() {
    this.settingService.settings.subscribe((data) => {
      this.setting = data;
    });
  }
}
