import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complete-registiration',
  templateUrl: './complete-registiration.component.html',
  styleUrls: ['./complete-registiration.component.scss']
})
export class CompleteRegistirationComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.router.navigate(['/requestes']);
    }, 2000);
  }

}
