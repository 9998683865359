import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-complet-regisration",
  templateUrl: "./complet-regisration.component.html",
  styleUrls: ["./complet-regisration.component.scss"],
})
export class CompletRegisrationComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    setTimeout(() => {
      this.router.navigate(["/"]);
    }, 2000);
  }
}
