import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CustomValidators } from "ng2-validation";
import { ShanabService } from "src/app/services/shanab.service";
import { RequestsService } from "../../services/requests.service";

@Component({
  selector: "app-add-sell-car",
  templateUrl: "./add-sell-car.component.html",
  styleUrls: ["./add-sell-car.component.scss"]
})
export class AddSellCarComponent implements OnInit {
  image: any;
  form: FormGroup;
  cities = [];
  brands = [];
  models = [];
  images = [];
  years = [];
  files = [];
  brand = "";
  addCar = true;
  carTypes = [
    "SUV",
    "Crossover",
    "Sedan",
    "PickUp",
    "Coupe",
    "Convertible",
    "Van",
    "Truck",
    "Bus",
    "Other"
  ];
  conditions = ["New", "Used", "Other"];
  constructor(
    private shanab: ShanabService,
    private router: Router,
    private requestsService: RequestsService
  ) {}
  year = [
    "1990",
    "1991",
    "1992",
    "1993",
    "1994",
    "1995",
    "1996",
    "1997",
    "1998",
    "1999",
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030"
  ];
  ngOnInit() {
    window.scrollTo(0, 0);

    this.form = new FormGroup({
      model: new FormControl("", [Validators.required]),
      year: new FormControl("", [Validators.required, CustomValidators.number]),
      city: new FormControl("", [Validators.required]),
      Mileage: new FormControl("", [
        Validators.required,
        CustomValidators.number
      ]),
      contactNumber: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
        )
      ]),
      underWarrenty: new FormControl(false, [Validators.required]),
      price: new FormControl("", [
        Validators.required,
        CustomValidators.number
      ]),
      images: new FormControl(null, [Validators.required]),

      condition: new FormControl("", [Validators.required]),
      carType: new FormControl("", [Validators.required]),
      color: new FormControl("#000", [Validators.required])
      // userType: new FormControl('User', [Validators.required]),
    });

    this.getAllCities();
    this.getAllBrands();
    console.log(this.form,'this.form');
  }

  getAllCities() {
    this.shanab.getAllCities().subscribe((data) => {
      this.cities = data.data;
    });
  }

  changeBrand() {
    if (this.brand != "") {
      this.years = this.year;
    }
    const brand = this.brands.find((e) => e._id == this.brand);

    this.models = brand ? brand.children : [];
  }

  getAllBrands() {
    this.shanab.getCarModels().subscribe((data) => {
      this.brands = data.data;
    });
  }
  chooseImage(event) {
    // let image: File = event.target.files;

    // let myReader: FileReader = new FileReader();
    // myReader.onloadend = (e) => {
    //   this.image = myReader.result;
    // };
    // myReader.readAsDataURL(image);

    this.images = [];
    this.files = [];
    let files = event.target.files;

    this.form.get("images").setValue(event.target.files);
    if (files) {
      for (let file of files) {
        this.files.push(file);
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.images.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  add() {
    if(!this.form.valid){
      let form = new FormData();
      this.addCar = false;

      this.files.forEach((img) => {
        form.append(img.name, img);
      });

      this.requestsService.uploadFile(form).subscribe((res) => {
        delete this.form.value.images;

        const data = {
          ...this.form.value,
          underWarrenty:
            this.form.get("underWarrenty").value == "true" ? true : false,
          images: res.data
        };
        //explicitly making phone number as string datatype
        data.contactNumber = data.contactNumber.toString();
        this.shanab.addSellCar(data).subscribe((data) => {
          this.addCar = true;

          this.router.navigateByUrl("/sell-your-car");
        });
      });
    }
  }
}
