import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-tickets",
  templateUrl: "./tickets.component.html",
  styleUrls: ["./tickets.component.scss"],
})
export class TicketsComponent implements OnInit {
  tickets = [];
  constructor(private shanab: ShanabService) {}

  ngOnInit() {
    this.shanab.getHelp().subscribe((data) => {
      this.tickets = data.data;
    });
  }
}
