import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/frontEnd/services/language.service";
import { AuthService } from "src/app/frontEnd/services/auth.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-menu-home",
  templateUrl: "./menu-home.component.html",
  styleUrls: ["./menu-home.component.scss"]
})
export class MenuHomeComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private languageService: LanguageService,private router: Router 
  ) {}
  lang = "AR";
  languages: any[] = [];
  selected: any = {};
  isAuth: boolean = !!localStorage.getItem("auth-token");
  username: string;

  ngOnInit() {
    this.authService.currentUser.subscribe((res) => {
      if (res) {
        this.username = res.name;
        this.isAuth = !!localStorage.getItem("auth-token");
        console.log(localStorage.getItem("auth-token"));
      }
    });
    this.languages = this.languageService.getLanguages();
    this.languageService.selectedLanguage.subscribe((res) => {
      if (res) {
        this.selected = res;
      }
    });
    if (localStorage.getItem("lang") === "en") this.lang = "AR";
    else if (localStorage.getItem("lang") === "ar") this.lang = "EN";
    else this.lang = "AR";
  }

  // changeLang() {
  //   if (localStorage.getItem('lang') === 'en') {
  //     localStorage.removeItem('lang');
  //     document.dir = 'rtl';
  //     this.translate.setDefaultLang('ar');
  //     localStorage.setItem('lang', 'ar');
  //     this.lang = 'EN';
  //     this.router.navigate(['']);

  //   } else {
  //     localStorage.removeItem('lang');
  //     this.translate.setDefaultLang('en');
  //     document.dir = 'ltr';
  //     localStorage.setItem('lang', 'en');
  //     this.router.navigate(['']);
  //     this.lang = 'AR';
  //   }
  // }

  logout() {
    this.authService.logout();
  }

  changeLang() {
    if (this.selected == "en") {
      this.lang = "EN";
      this.languageService.setLanguage("ar");
    } else if (this.selected == "ar") {
      this.lang = "AR";
      this.languageService.setLanguage("en");
    } else {
      this.lang = "AR";
      this.languageService.setLanguage("en");
    }
  }

  showDropdown() {}

  goTo(id) {
    const route = location.pathname;
    if(route != '/'){
      this.router.navigateByUrl('/');
        setTimeout(() => {
          document.getElementById(id).scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
          });
        }, 600);
    }else{
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest"
        });
      }, 100);
    }
  }
}
