import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild
} from "@angular/core";

@Component({
  selector: "app-place-order-step3",
  templateUrl: "./place-order-step3.component.html",
  styleUrls: ["./place-order-step3.component.scss"]
})
export class PlaceOrderStep3Component implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  @Output() serviceChange = new EventEmitter();
  @Input("service") service;
  @ViewChild("selectCar", { static: false }) selectCarFix;
  selectcar = false;
  selectcar2 = false;
  image: any;
  addimage = false;
  images = [];
  // tslint:disable-next-line: no-input-rename
  @Input("text") text = "Next";
  constructor(private router: Router) {}

  ngOnInit() {
    this.setSelectedType();
  }

  goToAddCar() {
    this.router.navigate(["/vehicles"]);
  }
  backClicked() {
    this.backChange.emit();
  }

  setSelectedType() {
    console.log(this.service);
    if (
      this.service.name.value == "Motor Inspection" ||
      this.service.name.value == "فحص المحرك"
    ) {
      this.service.price = 550;
      this.service.flag = true;
    } else if (
      this.service.name.value == "Car Accident" ||
      this.service.name.value == "حادث سيارة"
    ) {
      this.service.price = 700;
      this.service.flag = true;
    } else if (
      this.service.name.value == "Talk To Expert" ||
      this.service.name.value == "تحدث إلى الخبراء"
    ) {
      this.service.price = 50;
      this.service.flag = true;
    } else if (
      this.service.name.value == "Computer Check" ||
      this.service.name.value == "فحص الكمبيوتر"
    ) {
      this.service.price = 150;
      this.service.flag = true;
    } else {
      this.service.price = "";
      this.service.flag = false;
    }
    console.log(this.service);
  }

  nextClicked() {
    this.nextChange.emit(this.selectCarFix.getData());
  }

  selectCar() {
    if (this.selectcar2 === false) {
      this.selectcar = !this.selectcar;
    } else {
      this.selectcar = !!this.selectcar;
    }
  }

  selectCar2() {
    if (this.selectcar === false) {
      this.selectcar2 = !this.selectcar2;
    } else {
      this.selectcar2 = !!this.selectcar2;
    }
  }

  chooseImage(event) {
    let image: File = event.target.files[0];
    let myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.images.push(this.image);
    };
    myReader.readAsDataURL(image);
    this.addimage = true;
  }
}
