import { Component, OnInit, ViewChild } from "@angular/core";
// import {ScrollingModule} from '@angular/cdk/scrolling';
import { Observable } from "rxjs";
import { ChatService } from "../../services/chat.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { switchMap } from "rxjs/operators";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrManager } from "ng6-toastr-notifications"; 
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit {
  chatId = this.route.snapshot.params.chatId;
  messages: Observable<any[]>;
  newMsg = "";
  blocked: false;
  carDetails: any;
  car: any;
  checking: boolean = false;
  user = JSON.parse(localStorage.getItem('user-data'));

  constructor(
    private shanab: ShanabService,
    private chatService: ChatService,
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrManager,
    private chat: ChatService
  ) {}

  ngOnInit() {
    this.blocked = history.state.blocked;
    this.messages = this.chatService.getChatMessages(this.chatId);
    this.shanab.getCarDetails(this.chatId).subscribe((data: any) => {
      this.carDetails = data.data.length > 0 ? data.data[0] : null;
      // debugger
    });
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 1000);
  }

  sendMessage() {
    this.chatService.addChatMessage(this.newMsg, this.chatId).then(() => {
      this.newMsg = "";
    });
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 200);
  }

  confirmBox(chatId) {
    if (!this.blocked) {
      Swal.fire({
        title: 'Blocked!',
        text: 'Are you sure want to blocked?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, blocked it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
        
          this.chatService.blockChatRoom(this.chatId).subscribe((res) => {
            this.router.navigate(["/"]);
          },
            (err) => {
              this.router.navigate(["/"]);
            });
       
        }
      })
    }
    else {
      this.toastr.errorToastr("Already Blocked");
    }
  }
  goToAdvertisement(sellCarId) {
    this.router.navigate([`/add-sell-car-details/${ sellCarId }`]);
    console.log("idr hn" + sellCarId);
  }

}
