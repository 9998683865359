import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-step3-regisration',
  templateUrl: './step3-regisration.component.html',
  styleUrls: ['./step3-regisration.component.scss'],
})
export class Step3RegisrationComponent implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  @Output() code = new EventEmitter();
  @Output() resend = new EventEmitter();
  @Input() type = '';

  countDown: Subscription;
  counter = 300;
  tick = 1000;

  blur = false;

  constructor() {}

  ngOnInit() {
    if (this.type == 'email') {
      this.nextClicked();
    }
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter > 0) {
        --this.counter;
      } else {
      }
    });
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.countDown = null;
  }

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    this.nextChange.emit();
  }

  resendCode() {
    this.resend.emit();
  }

  setCode(event) {
    this.code.emit(event);
  }
}
