import { Component, Input, OnInit } from "@angular/core";
import { tap } from "rxjs/operators";
import { SimpleFilterService } from "../services/car-filter.service";

@Component({
  selector: "app-car-filter",
  templateUrl: "./car-filter.component.html",
  styleUrls: ["./car-filter.component.scss"]
})
export class CarFilterComponent implements OnInit {
  @Input() filterCarsFunction: any;

  filter = {
    city: "",
    brand: "",
    model: "",
    type: "",
    underWarrenty: "",
    minprice: "",
    maxprice: "",
    minyear: "",
    maxyear: "",
    minrange: "",
    maxrange: "",
    user: "",
    page: 1,
    pageSize: 10,
    total: 0
  };
  cities = [];
  models = [];
  loading = true;
  years = [];
  more = false;
  carTypes = [];
  brands = [];
  filteredCarTypes = [];
  selected = "";

  constructor(private simpleFilterService: SimpleFilterService) {
    this.simpleFilterService.filter$.subscribe((newFilter) => {
      this.filter = newFilter;
    });

    this.simpleFilterService.years$.subscribe((years) => {
      this.years = years;
    });

    this.simpleFilterService.filteredCarTypes$.subscribe((carTypes) => {
      this.carTypes = carTypes;
      this.filteredCarTypes = carTypes;
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.simpleFilterService.resetVariables();

    this.simpleFilterService.filter$
      .pipe(
        tap((newFilter) => {
          this.filter = newFilter;
          this.getAllCities();
          this.getAllBrands();
        })
      )
      .subscribe();

    this.simpleFilterService.years$
      .pipe(
        tap((years) => {
          this.years = years;
        })
      )
      .subscribe();

    this.simpleFilterService.filteredCarTypes$
      .pipe(
        tap((carTypes) => {
          this.carTypes = carTypes;
          this.filteredCarTypes = carTypes;
        })
      )
      .subscribe();
  }

  getAllBrands() {
    this.simpleFilterService.filteredBrands$.subscribe((brands) => {
      this.brands = brands;
    });
  }

  getAllCities() {
    this.simpleFilterService.getAllCities().subscribe((res: any) => {
      if (res) {
        this.cities = res.data;
      }
    });
  }

  filterCars = () => {
    if (this.filterCarsFunction) {
      this.filterCarsFunction(this.filter);
    } else this.simpleFilterService.navigateTo("/search", { ...this.filter });
  };

  setFilterVal = (filter: string, val: string) => {
    this.filter[filter] = val;
    if (filter === "brand") {
      this.selected = this.brands.filter((b) => b._id === val)[0].title;
    }
  };

  filterBrands = (val) => {
    this.simpleFilterService.filterBrands(val);
  };

  filterCarTypes = (val) => {
    this.simpleFilterService.filterCarTypes(val);
  };

  openModal() {
    this.simpleFilterService.resetVariables();
  }
}
