import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-offer-accepet-page",
  templateUrl: "./offer-accepet-page.component.html",
  styleUrls: ["./offer-accepet-page.component.scss"],
})
export class OfferAccepetPageComponent implements OnInit {
  offerId: string;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.offerId = this.route.snapshot.params.offerId;
  }

  backBtn() {
    this.router.navigate(["/order-offers"]);
  }

  nextBtn() {
    this.router.navigate(["/pay-to-transport/" + this.offerId]);
  }
}
