import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { SharedModule } from "./frontEnd/components/shared.module";
import { SharedPageModule } from "./frontEnd/pages/shared-page.module";
import { HttpIntercept } from "./frontEnd/services/http-intercept";
import { ConsumerGuard } from "./frontEnd/services/consumer-guard.service";
import { WorkshopGuard } from "./frontEnd/services/workshop-guard.service";
import { NgbPaginationConfig } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ng6-toastr-notifications";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxGalleryModule } from "ngx-gallery";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "../environments/environment";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    HttpClientModule,
    SharedPageModule,
    SharedModule,
    NgxGalleryModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpIntercept, multi: true },
    ConsumerGuard,
    WorkshopGuard,
    NgbPaginationConfig
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

// ngx
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
