import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import * as $ from "jquery";

declare let jQuery;
@Component({
  selector: "app-supplier-offers",
  templateUrl: "./supplier-offers.component.html",
  styleUrls: ["./supplier-offers.component.scss"],
})
export class SupplierOffersComponent implements OnInit {
  statuses = [];
  status = "unconfirmed";
  page = 1;
  offers = [];
  paging: any;
  selectOffer: any;
  loading: boolean = false;
  enableModal = false;

  constructor(private shanab: ShanabService) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.shanab.getOrderStatus().subscribe((data) => {
      this.statuses = data.data;
    });
    this.getData();
  }

  getData() {
    this.loading = true;
    this.shanab
      .getOffersForSupplier({ page: this.page, status: this.status })
      .subscribe(
        (data) => {
          this.loading = false;
          this.offers = data.data;
          this.paging = data.paging;
        },
        () => {
          this.loading = false;
        }
      );
  }

  selectStatus(status) {
    this.status = status;
    this.getData();
  }

  changePage(event) {
    
    if (event) {
      this.page = event;
    }
    this.getData();
  }

  confirm() {
    this.shanab.goToNextStatus(this.selectOffer).subscribe((data) => {
      this.getData();
      jQuery("#exampleModalCenter").modal("hide");
    });
    // this.shanab.
  }
  setStarted(offer) {
    this.selectOffer = offer._id;
  }
}
