import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-supplier-shop-service",
  templateUrl: "./supplier-shop-service.component.html",
  styleUrls: ["./supplier-shop-service.component.scss"],
})
export class SupplierShopServiceComponent implements OnInit {
  user: any;
  types = [];

  allTypes = [];
  constructor(private auth: AuthService, private shanab: ShanabService) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.shanab.getAllOrderTypes().subscribe((data) => {
      this.allTypes = data.data;
    });
    this.auth.currentUser.subscribe((data) => {
      if (data) {
        this.user = data;
        this.types = this.user.types.map((e) => e._id);
      }
    });
  }

  selectType(type) {
    if (this.checkSelected(type._id)) {
      this.types = this.types.filter((e) => e != type._id);
    } else {
      this.types.push(type._id);
    }
  }

  checkSelected(id) {
    return this.types.includes(id) ? true : false;
  }

  update() {
    this.shanab
      .updateSupplierProfile({ types: this.types })
      .subscribe((data) => {
        this.auth.currentUser.next(data.data);
      });
  }
}
