import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ToastrManager } from "ng6-toastr-notifications";

@Component({
  selector: "app-supplier-register-step2",
  templateUrl: "./supplier-register-step2.component.html",
  styleUrls: ["./supplier-register-step2.component.scss"],
})
export class SupplierRegisterStep2Component implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  @Output() phone = new EventEmitter();

  type = 'phone';
  email = '';
  password = '';
  phoneNumber = '';
  termsAndCondition: boolean = false;
  constructor(public toastr: ToastrManager) {}


  ngOnInit() {}

  checkCheckBoxvalue(event) {
    const checkTerms = event.target.checked;
    if (checkTerms === true) {
      this.termsAndCondition = true;
      return true;
    }
    this.termsAndCondition = false;
    return false;
  }

  backClicked() {
    this.backChange.emit();
  }

  setPhone() {
    if (this.email == "" || this.type == "" || this.password == "") {
      this.toastr.errorToastr("Please fill the Required Fields");
      return false;
    }
    if(this.termsAndCondition === false){
      this.toastr.errorToastr("Please check the agreement box to continue");
      return false;
    }
    // this.phone.emit(e.value);
    this.phone.emit({ phoneNumber: this.phoneNumber , type: this.type, email: this.email, password: this.password });

  }

  nextClicked(phoneNumber) {
    this.nextChange.emit();
  }

  changeType(type) {
    this.type = type;
  }
}
