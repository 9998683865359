import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicles-page',
  templateUrl: './vehicles-page.component.html',
  styleUrls: ['./vehicles-page.component.scss']
})
export class VehiclesPageComponent implements OnInit {
  myCar = false;
  constructor(private router: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);

  }

  goToMyCar() {
    this.myCar = true;
  }
}
