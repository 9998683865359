import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ChatService } from "src/app/frontEnd/services/chat.service";

@Component({
  selector: "app-chat-listing",
  templateUrl: "./chat-listing.component.html",
  styleUrls: ["./chat-listing.component.scss"],
})
export class ChatListingPage implements OnInit {
  constructor(private router: Router, private chatService: ChatService) {}

  data: any[] = [];

  ngOnInit() {
    this.chatService.fetchChatRoom().subscribe(
      (res) => {
        this.data = res.data;
      },
      (err) => {}
    );
  }

  goBack() {
    this.router.navigate(["/"]);
  }

  openChat(chatId,carDetails,blocked) {
    this.router.navigateByUrl("/chat", { state: {  chatId ,carDetails, blocked} });
  }
}
