import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Subscription, timer } from "rxjs";

@Component({
  selector: "app-supplier-register-step3",
  templateUrl: "./supplier-register-step3.component.html",
  styleUrls: ["./supplier-register-step3.component.scss"],
})
export class SupplierRegisterStep3Component implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  @Output() code = new EventEmitter();
  @Input() type = '';

  countDown: Subscription;
  counter = 300;
  tick = 1000;
  blur = false;
  constructor() {}

  ngOnInit() {

    if(this.type == 'email') {
      
      this.nextClicked();
    }
    
    this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.countDown = null;
  }

  backClicked() {
    this.backChange.emit();
  }

  setCode(e) {
    this.code.next(e);
  }

  nextClicked() {
    this.nextChange.emit();
  }
}
