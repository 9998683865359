import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { CustomValidators } from "ng2-validation";
import { ShanabService } from "src/app/services/shanab.service";
import { AuthService } from "../../services/auth.service";
import { RequestsService } from "../../services/requests.service";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
  form: FormGroup;
  dob = "";

  constructor(
    private shanab: ShanabService,
    private authService: AuthService,

    private router: Router,
    private requestsService: RequestsService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getCurrentDate();
    this.form = new FormGroup({
      dob: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, CustomValidators.email]),
      phone: new FormControl("", [Validators.required, CustomValidators.phone]),
      gender: new FormControl("", [Validators.required]),
    });

    this.authService.currentUser.subscribe((data) => {
      if (data) {
        this.form.patchValue(data);
        var localDate = new Date(data.dob);
        this.dob =
          localDate.getFullYear() +
          "-" +
          ((localDate.getMonth() + 1).toString().length === 1
            ? "0" + (localDate.getMonth() + 1).toString()
            : (localDate.getMonth() + 1).toString()) +
          "-" +
          (localDate.getDate().toString().length === 1
            ? "0" + localDate.getDate().toString()
            : localDate.getDate().toString());
        this.form.get("dob").setValue(this.dob);
      }
    });
  }

  changeDob() {
    this.form.get("dob").setValue(this.dob.replace("-", "/").replace("-", "/"));
  }

  add() {
    this.shanab.updateProfile(this.form.value).subscribe((res) => {
      this.authService.getProfileData();
      this.router.navigateByUrl("/profile");
    });
  }

  getCurrentDate() {
    var localDate = new Date();
    return (
      localDate.getFullYear() +
      "-" +
      ((localDate.getMonth() + 1).toString().length === 1
        ? "0" + (localDate.getMonth() + 1).toString()
        : (localDate.getMonth() + 1).toString()) +
      "-" +
      (localDate.getDate().toString().length === 1
        ? "0" + localDate.getDate().toString()
        : localDate.getDate().toString())
    );
  }
}
