import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ToastrManager } from "ng6-toastr-notifications";
import { BackNextButtonsComponent } from "../../back-next-buttons/back-next-buttons.component";

@Component({
  selector: "app-step2-regisration",
  templateUrl: "./step2-regisration.component.html",
  styleUrls: ["./step2-regisration.component.scss"],
})
export class Step2RegisrationComponent implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  @Output() phone = new EventEmitter();
  @ViewChild("inputPhone", { static: false }) inputPhone: ElementRef;
  @ViewChild("inputEmail", { static: false }) inputEmail: ElementRef;
  @ViewChild(BackNextButtonsComponent, { static: false })
  nextButton: BackNextButtonsComponent;
  @ViewChild("next", { static: false })
  next: BackNextButtonsComponent;

  isAccept: boolean = false;
  isPassword: boolean = true;
  type = "phone";
  email = "";
  password = "";
  phoneNumber = "";
  show: string;
  termsAndCondition: boolean = false; 
  constructor(public toastr: ToastrManager) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.getInput(this.type);
  }

  getInput(type) {
    setTimeout(() => {
      if (type == "phone") {
        this.inputPhone.nativeElement.focus();
      } else {
        this.inputEmail.nativeElement.focus();
      }
    }, 300);
  }

  checkCheckBoxvalue(event) {
    const checkTerms = event.target.checked;
    if (checkTerms === true) {
      this.termsAndCondition = true;
      return true;
    }
    this.termsAndCondition = false;
    return false;
  }

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    // const phoneNumber :any = document.getElementById('phoneNumber');
    
    if (this.email == "" || this.type == "" || this.password == "") {
      this.toastr.errorToastr("Please fill the Required Fields");
      return false;
    }
    if(this.termsAndCondition === false){
      this.toastr.errorToastr("Please check the agreement box to continue");
      return false;
    }
    console.log(this.email,this.type,this.password)

    this.phone.emit({
      phoneNumber: this.phoneNumber,
      type: this.type,
      email: this.email,
      password: this.password,
    });
  }

  // changeLang() {
  //   if (this.selected == "en") {
  //     this.lang = "AR";
  //   } else {
  //     this.lang = "EN";
  //     this.languageService.setLanguage("en");
  //   }
  // }

  changeType(type) {
    this.getInput(type);
    this.type = type;
  }

  showPassword() {
    this.isPassword = !this.isPassword;
  }
}
