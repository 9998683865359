import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-add-vechile",
  templateUrl: "./add-vechile.component.html",
  styleUrls: ["./add-vechile.component.scss"],
})
export class AddVechileComponent implements OnInit {
  constructor(private shanab: ShanabService) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.shanab.getCarModels().subscribe((data) => {});
  }
}
