import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LanguageService {
  language: any;
  selectedLanguage = new BehaviorSubject(null);

  constructor(private translate: TranslateService) {}

  setInitialAppLanguage() {
    const lang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : this.translate.getBrowserLang();
    this.language = this.getLanguages().find((e) => e.value == lang);

    this.translate.use(this.language.value);
    this.selectedLanguage.next(this.language.value);

    if (lang == "ar") {
      document.documentElement.dir = "rtl";
    } else {
      document.documentElement.dir = "ltr";
    }
  }

  getLanguages() {
    return [
      { text: "العربية", value: "ar" },
      { text: "English", value: "en" }
    ];
  }

  setLanguage(language: any) {
    if (language == "ar") {
      document.documentElement.dir = "rtl";
    } else {
      document.documentElement.dir = "ltr";
    }

    localStorage.setItem("lang", language);
    this.translate.use(language);
    this.selectedLanguage.next(language);
  }
}
