import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"],
})
export class ProfilePageComponent implements OnInit {
  profileData: any;
  imageFile: any;

  currentLocation: any; //= localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : null ;

  constructor(
    private router: Router,
    private shanab: ShanabService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.authService.currentUser.subscribe((res) => {
      if (res) {
        this.currentLocation = res.location;

        this.profileData = res;
      }
    });
  }

  updateLocation() {
    this.shanab
      .updateSupplierProfile({
        coordinates: JSON.parse(localStorage.getItem("location")).coordinates,
      })
      .subscribe((data) => {});
  }
  pickImage(event) {
    var image = event.target.files[0];
    this.imageFile = image;
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.profileData.images = [{ url: "" }];
      this.profileData.images[0].url = myReader.result;
    };
    myReader.readAsDataURL(image);
    this.uploadImage();
  }

  uploadImage() {
    if (this.profileData.role.includes("supplier")) {
      this.shanab.uploadFile([this.imageFile]).subscribe((res) => {
        this.shanab
          .updateSupplierProfile({ images: res.data })
          .subscribe((result: any) => {
            this.profileData.images = [{ url: "" }];
            this.profileData.images[0].url = result.data.images[0].url;
          });
      });
    } else {
      this.shanab.uploadFile([this.imageFile]).subscribe((res) => {
        this.shanab
          .updateProfile({ images: res.data })
          .subscribe((result: any) => {
            this.profileData.images = [{ url: "" }];
            this.profileData.images[0].url = result.data.images[0].url;
          });
      });
    }
  }

  goMap() {
    window.open(
      `http://www.google.com/maps/place/${this.profileData.location.coordinates[0]},${this.profileData.location.coordinates[1]}`
    );
  }

  goToVehicle() {
    this.router.navigate(["/vehicles"]);
  }
  goToHelp() {
    this.router.navigate(["/help"]);
  }
  goToShopService() {
    this.router.navigate(["/shop-services"]);
  }
}
