import { Router, ActivatedRoute } from "@angular/router";
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import { NavParamsService } from "../../services/nav-params.service";

@Component({
  selector: "app-order-detils-page",
  templateUrl: "./order-detils-page.component.html",
  styleUrls: ["./order-detils-page.component.scss"],
})
export class OrderDetilsPageComponent implements OnInit {
  orderId: string;
  order: any;

  constructor(
    private router: Router,
    private navParams: NavParamsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.orderId = this.route.snapshot.params.orderId;
  }

  showOffersDetails(orderId) {
    this.orderId = orderId;
    this.navParams.setValue({ orderId: orderId });
    setTimeout(() => {
      document.getElementById("orderOffers").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 50);
  }

  setOrder(e) {
    this.order = e;
  }

  goToPayShowShopsInfo() {
    this.router.navigate(["/Pay-to-show-shops/" + this.orderId]);
  }
}
