import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { RequestsService } from "src/app/frontEnd/services/requests.service";
import { AuthService } from "src/app/frontEnd/services/auth.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-step4-regisration",
  templateUrl: "./step4-regisration.component.html",
  styleUrls: ["./step4-regisration.component.scss"],
})
export class Step4RegisrationComponent implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  image: any;
  userForm: FormGroup;
  dob = "";

  constructor(
    private fb: FormBuilder,
    private requestsService: RequestsService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.initForm();
    this.authService.currentUser.subscribe((data) => {
      this.userForm.patchValue(data);
    });
  }

  initForm() {
    this.userForm = this.fb.group({
      image: [''],
      dob: [''],
      gender: [''],
      name: ['', Validators.required],
      email: [''],
      phone: [''],
    });
  }

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    this.updateProfile();
  }

  changeDob() {


    this.userForm.get('dob').setValue( this.dob.replace('-', '/').replace('-', '/'))
    
  }

  updateProfile() {
    if (this.userForm.get("image").value) {
      this.requestsService
        .uploadFile([this.userForm.get("image").value])
        .subscribe((res) => {
          delete this.userForm.value.image;

          const profileData = {
            ...this.userForm.value,
            images: res.data,
          };
          this.requestsService
            .updateProfile(profileData)
            .subscribe((result: any) => {
              this.authService.currentUser.next(result.data);
              localStorage.setItem("user-data", JSON.stringify(result.data));
              this.nextChange.emit();
              localStorage.setItem("auth-role", result["data"]["role"][0]);
              localStorage.setItem("auth-name", result["data"]["name"]);
            });
        });
    } else {
      delete this.userForm.value.image;
      const profileData = {
        ...this.userForm.value,
        images: [],
      };
      this.requestsService
        .updateProfile(profileData)
        .subscribe((result: any) => {
          this.authService.currentUser.next(result.data);
          localStorage.setItem("user-data", JSON.stringify(result.data));
          this.nextChange.emit();
          localStorage.setItem("auth-role", result["data"]["role"][0]);
          localStorage.setItem("auth-name", result["data"]["name"]);
        });
    }
  }

  chooseImage(event) {
    let image: File = event.target.files[0];
    this.userForm.get("image").setValue(event.target.files[0]);

    let myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
    };
    myReader.readAsDataURL(image);
  }
}
