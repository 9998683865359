// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDvk3ic4ziqo4q1sKFz3M3_0-AaFC6mzOE",
    authDomain: "shanab-26b75.firebaseapp.com",
    projectId: "shanab-26b75",
    storageBucket: "shanab-26b75.appspot.com",
    messagingSenderId: "782566101580",
    appId: "1:782566101580:web:28a0b74c10b30e09e5dae9",
    measurementId: "G-PJW8TQ1BZL",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
