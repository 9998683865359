import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-place-order",
  templateUrl: "./place-order.component.html",
  styleUrls: ["./place-order.component.scss"]
})
export class PlaceOrderComponent implements OnInit {
  step = [];
  orderForm: FormGroup;
  orderTypeFix: string = "";
  serviceSelected: any = {};
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private shanabService: ShanabService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.initForm();

    this.orderForm
      .get("location")
      .setValue(
        localStorage.getItem("location")
          ? JSON.parse(localStorage.getItem("location"))
          : {}
      );
  }

  initForm() {
    this.orderForm = this.fb.group({
      myCar: ["", Validators.required],
      orderType: ["", Validators.required],
      comment: ["", Validators.required],
      orderBrand: ["", Validators.required],
      location: [null, Validators.required],
      images: [[], Validators.required],
      customBrand: [false, Validators.required]
    });
  }

  valueChanged() {
    this.step.push(1);
  }

  setSelectedType(event) {
    this.orderTypeFix = event.serviceType == "Workshop" ? "general" : "shanab";
    this.orderForm.get("orderType").setValue(event._id);
    this.serviceSelected = event;
  }

  backValueChanged() {
    this.step.pop();
  }

  sendOrder() {
    this.router.navigate(["/myorders"]);
  }

  submit(e) {
    console.log(e);
    if (e.show == true) {
      Swal.fire(
        "Success!",
        "Our team member will contact you shortly",
        "success"
      ).then(() => {});
    }
    this.orderForm.get("comment").setValue(e.comment);
    this.orderForm.get("images").setValue(e.images);
    this.orderForm.get("myCar").setValue(e.myCar);
    this.orderForm.get("myCar").setValue(e.myCar);
    this.orderForm.get("customBrand").setValue(e.customBrand);
    this.orderForm.get("orderBrand").setValue(e.orderBrand);

    if (this.orderForm.valid) {
      this.shanabService
        .uploadFile(this.orderForm.get("images").value)
        .subscribe((res) => {
          const orderData = {
            myCar: this.orderForm.get("myCar").value,
            orderType: this.orderForm.get("orderType").value,
            orderBrand: this.orderForm.get("orderBrand").value,
            orderTypeFix: this.orderTypeFix,
            customBrand: this.orderForm.get("customBrand").value,
            comment: this.orderForm.get("comment").value,
            location: localStorage.getItem("location")
              ? JSON.parse(localStorage.getItem("location"))
              : {},
            images: res.data
          };

          this.shanabService.addOrder(orderData).subscribe((result: any) => {
            this.router.navigate(["myorders"]);
          });
        });
    }
  }
}
