import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { ShanabService } from "src/app/services/shanab.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-add-ticket",
  templateUrl: "./add-ticket.component.html",
  styleUrls: ["./add-ticket.component.scss"],
})
export class AddTicketComponent implements OnInit {
  form: FormGroup;
  constructor(private shanab: ShanabService, private router: Router) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.form = new FormGroup({
      question: new FormControl("", [Validators.required]),
    });
  }

  valid(controlName, errorName) {
    return (
      this.form.get(controlName).hasError(errorName) &&
      (this.form.get(controlName).dirty || this.form.get(controlName).touched)
    );
  }

  send() {
    this.shanab.sendHelp(this.form.value).subscribe((data) => {
      this.form.reset();
      this.router.navigateByUrl("/tickets");
    });
  }
}
