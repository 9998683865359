import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { ShanabService } from 'src/app/services/shanab.service';
import { RequestsService } from '../../services/requests.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;

  constructor(private shanab: ShanabService, private router: Router, private requestsService: RequestsService) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.form = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
    });
  }

  add() {
    this.shanab.editPassword(this.form.value).subscribe((res) => {
      this.router.navigateByUrl('/profile');
    });
  }
}
