import { Component, OnInit, HostListener, Renderer2 } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SimpleFilterService } from "../../components/services/car-filter.service";

@Component({
  selector: "app-search-cars",
  templateUrl: "./search-cars.component.html",
  styleUrls: ["./search-cars.component.scss"],
})
export class SearchCarsComponent implements OnInit {
  isScrolled: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private simpleFilterService: SimpleFilterService,
    private renderer: Renderer2
  ) {}
  @HostListener("window:scroll", [])
  onScroll(): void {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    // Adjust the scroll threshold based on your requirements
    const scrollThreshold = 100;

    // Add or remove the 'scrolled' class based on the scroll position
    if (scrollPosition >= scrollThreshold) {
      this.renderer.addClass(document.body, "scrolled");
      this.isScrolled = true;
    } else {
      this.renderer.removeClass(document.body, "scrolled");
      this.isScrolled = false;
    }
  }

  cars = [];
  loading = true;
  filter = null;

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.filter = {
        city: params["city"],
        brand: params["brand"],
        model: params["model"],
        type: params["type"],
        underWarrenty: params["underWarrenty"],
        minprice: params["minprice"],
        maxprice: params["maxprice"],
        minyear: params["minyear"],
        maxyear: params["maxyear"],
        minrange: params["minrange"],
        maxrange: params["maxrange"],
        user: params["user"],
        page: 1,
        pageSize: 10,
        total: 0,
      };
      this.loadCars(this.filter);
    });
  }

  loadCars = (filter?: any) => {
    this.simpleFilterService.getAllSellCars(filter).subscribe(
      (res: any) => {
        this.cars = res.data;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  };
}
