import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"],
})
export class TermsComponent implements OnInit {
  terms: any;

  constructor(private shanab: ShanabService) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.shanab.settings.subscribe((data: any) => {
      if (data) {
        this.terms = data.tearms[0].value;
      }
    });
  }
}
