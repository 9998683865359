import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-my-orders-header',
  templateUrl: './my-orders-header.component.html',
  styleUrls: ['./my-orders-header.component.scss']
})
export class MyOrdersHeaderComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('text') text = '';
  // tslint:disable-next-line: no-input-rename
  @Input('headerParagraf') headerParagraf;
  constructor() { }

  ngOnInit() {
  }

}
