import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-verifycation-otp",
  templateUrl: "./verifycation-otp.component.html",
  styleUrls: ["./verifycation-otp.component.scss"],
})
export class VerifycationOtpComponent implements OnInit {
  @ViewChild("input0", { static: false }) input0;
  code = "";
  @Output("loginCode") loginCode = new EventEmitter();
  constructor(private router: Router) {}

  ngOnInit() {}

  setFocusOnFirst() {
    if (!(this.input0.nativeElement["value"] > 0)) {
      this.input0.focus();
    }
  }

  setVerificationCode(event, nextInput: any, lastInput: any) {
    this.code = "";
    let codeInputs = document.getElementsByClassName("code-input");

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < codeInputs.length; i++) {
      this.code += codeInputs[i]["value"].substr(0, 1);
    }
    if (event.target.value.length > 0) {
      if (nextInput) {
        nextInput.focus();
      }
    }
    // tslint:disable-next-line: triple-equals
    if (event.target.value.length == 0) {
      if (lastInput) {
        lastInput.focus();
      }
    }

    this.loginCode.emit(this.code);
  }
}
