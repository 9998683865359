import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { SimpleFilterService } from "../services/car-filter.service";

@Component({
  selector: "app-modal-filter",
  templateUrl: "./modal-filter.component.html",
  styleUrls: ["./modal-filter.component.scss"]
})
export class ModalFilterComponent implements AfterViewInit {
  filter = {
    city: "",
    brand: "",
    model: "",
    type: "",
    underWarrenty: "",
    minprice: "",
    maxprice: "",
    minyear: "",
    maxyear: "",
    minrange: "",
    maxrange: "",
    user: "",
    page: 1,
    pageSize: 10,
    total: 0
  };
  cities = [];
  cars = [];
  models = [];
  loading = true;
  yearsMin = [];
  yearsMax = [];
  more = false;
  carTypes = [];
  brands = [];
  filteredCarTypes = [];
  selected = "";

  constructor(private simpleFilterService: SimpleFilterService) {
    this.simpleFilterService.filter$.subscribe((newFilter) => {
      this.filter = newFilter;
    });

    this.simpleFilterService.filteredYearsMin$.subscribe((years) => {
      this.yearsMin = years;
    });

    this.simpleFilterService.filteredYearsMax$.subscribe((years) => {
      this.yearsMax = years;
    });

    this.simpleFilterService.filteredCarTypes$.subscribe((carTypes) => {
      this.carTypes = carTypes;
      this.filteredCarTypes = carTypes;
    });
  }
  ngAfterViewInit(): void {
    $("#exampleModalCenter").on("hidden.bs.modal", (e) => {
      this.modalClose();
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.simpleFilterService.resetVariables();
    this.simpleFilterService.updateFilter(this.filter);
    this.getAllCities();
    this.getAllBrands();
  }

  getAllBrands() {
    this.simpleFilterService.filteredBrands$.subscribe((brands) => {
      this.brands = brands;
    });
  }

  getAllCities() {
    this.simpleFilterService.getAllCities().subscribe((res: any) => {
      if (res) {
        this.cities = res.data;
      }
    });
  }

  setFilter() {
    this.simpleFilterService.filter$.subscribe((data) => {
      this.filter = data;
    });
  }

  filterCars = () => {
    ($("#exampleModalCenter") as any).modal("hide");
    this.simpleFilterService.navigateTo("/search", { ...this.filter });
  };

  setFilterVal = (filter: string, val: string) => {
    this.filter[filter] = val;
    if (filter === "brand") {
      this.selected = this.brands.filter((b) => b._id === val)[0].title;
    }
  };

  filterBrands = (val) => {
    this.simpleFilterService.filterBrands(val);
  };

  filterCarTypes = (val) => {
    this.simpleFilterService.filterCarTypes(val);
  };

  filterYearsMin = (val) => {
    this.simpleFilterService.filterYearsMin(val);
  };

  filterYearsMax = (val) => {
    this.simpleFilterService.filterYearsMax(val);
  };

  modalClose() {
    this.simpleFilterService.resetVariables();
  }
}
