import { Component, OnInit, Output, EventEmitter, Type, Input } from '@angular/core';
import { NavParamsService } from 'src/app/frontEnd/services/nav-params.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ShanabService } from 'src/app/services/shanab.service';
import * as $ from 'jquery';


@Component({
  selector: 'app-order-details-card',
  templateUrl: './order-details-card.component.html',
  styleUrls: ['./order-details-card.component.scss'],
})
export class OrderDetailsCardComponent implements OnInit {
  @Output() offersClicked = new EventEmitter();
  @Output() order = new EventEmitter();

  @Input('orderId') orderId;
  select = false;
  select2 = false;
  data: any = {};
  showModal: false;

  constructor(
    private navParams: NavParamsService,
    private router: Router,
    private shanabService: ShanabService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.navParams.params) {
      if (this.navParams.params['order']) {
        this.data = this.navParams.params['order'];
        this.order.emit(this.data);
      } else {
        this.getOrder();
      }
    } else {
      this.getOrder();
    }
  }

  getOrder() {
    this.shanabService.getOrder(this.orderId).subscribe((res: any) => {
      if (res) {
        this.data = res.data[0];
        this.order.emit(this.data);
      }
    });
  }

  selectType() {
    this.select = !this.select;
  }

  selectType2() {
    this.select2 = !this.select2;
  }

  offersClick() {
    this.offersClicked.emit(this.data._id);
  }

  cancelRequest() {

    
    this.shanabService.cancelOrder({ orderId: this.data._id }).subscribe((res) => {
      // document.getElementById('exampleModal').style.display = 'none';
      $(".close-modal").click();

      // document.getElementsByClassName('modal-backdrop')[0]['style'].display = 'none';
      this.router.navigate(['/myorders']);


    });
  }
}
