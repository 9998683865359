import { DebugElement, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BaseUrl } from "src/app/config";
import { ToastrManager } from "ng6-toastr-notifications";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HttpInitService {
  private baseUrl = BaseUrl + "api/";
  constructor(private httpClient: HttpClient, public toastr: ToastrManager) {}

  public postRequest(url, data, dontShowMsg?) {
    return this.httpClient.post(this.baseUrl + url, data).pipe(
      map((e: any) => {
        if (!dontShowMsg) {
          this.toastr.successToastr(e.message, "");
        }
        return e;
      }),
      catchError((e: any) => {
        this.toastr.errorToastr(e.error.message, "");
        return e;
      })
    );
  }
  public deleteRequest(url, dontShowMsg?) {
    return this.httpClient.delete(this.baseUrl + url).pipe(
      map((e: any) => {
        if (!dontShowMsg) {
          this.toastr.successToastr(e.message, "");
        }
        return e;
      }),
      catchError((e: any) => {
        this.toastr.errorToastr(e.error.message, "");
        return e;
      })
    );
  }

  public uploadRequest(url, data) {
    const formData = new FormData();
    if (data) {
      data.forEach((picture: any) => {
        formData.append(
          "images",
          picture,
          new Date().getUTCMilliseconds().toString() + ".jpeg"
        );
      });
    }
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    return this.httpClient
      .post(this.baseUrl + url, formData, {
        headers: headers,
      })
      .pipe(
        map((e: any) => {
          return e;
        }),
        catchError((e: any) => {
          this.toastr.errorToastr(e.error.message, "");
          return e;
        })
      );
  }

  public putRequest(url, data) {
    return this.httpClient.put(this.baseUrl + url, data).pipe(
      map((e: any) => {
        this.toastr.successToastr(e.message, "");
        return e;
      }),
      catchError((e: any) => {
        this.toastr.errorToastr(e.error.message, "");
        return e;
      })
    );
  }

  public getRequest(url, params = {}) {
    return this.httpClient.get(this.baseUrl + url, { params });
  }

  public postWithParamsRequest(url, data) {
    let paramsData;
    if (data) {
      if (data.searchWord) {
        paramsData = new HttpParams().set("searchWord", data.searchWord);
      }
    }

    return this.httpClient
      .post(this.baseUrl + url, data, {
        params: paramsData,
      })
      .pipe(
        map((e: any) => {
          return e;
        }),
        catchError((e: any) => {
          this.toastr.errorToastr(e.error.message, "");
          return e;
        })
      );
  }

  public getWithParamsRequest(url, params: any) {
    let paramsData;
    if (params) {
      if (params.orderTypeFix) {
        if (params.page) {
          paramsData = new HttpParams()
            .set("page", params.page)
            .set("orderTypeFix", params.orderTypeFix);
        } else {
          paramsData = new HttpParams().set(
            "orderTypeFix",
            params.orderTypeFix
          );
        }
      }

      if (params.accept) {
        if (params.page) {
          paramsData = new HttpParams()
            .set("accept", params.accept)
            .set("page", params.page);
        } else {
          paramsData = new HttpParams().set("accept", params.accept);
        }
      }

      if (params.status) {
        if (params.page) {
          paramsData = new HttpParams()
            .set("status", params.status)
            .set("page", params.page);
        } else {
          paramsData = new HttpParams().set("status", params.status);
        }
      }

      if (params.supplierId) {
        paramsData = new HttpParams().set("supplierId", params.supplierId);
      }
    }
    return this.httpClient.get(this.baseUrl + url, { params: paramsData });
  }
}
