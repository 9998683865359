import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-place-order-step2",
  templateUrl: "./place-order-step2.component.html",
  styleUrls: ["./place-order-step2.component.scss"]
})
export class PlaceOrderStep2Component implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  @Output() selectedType = new EventEmitter();
  select = false;
  select2 = false;
  select3 = false;
  select4 = false;
  select5 = false;

  constructor() {}

  ngOnInit() {}

  setSelectedType(event) {
    console.log(event);
    this.selectedType.emit(event);
  }

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    this.nextChange.emit();
  }

  selectType() {
    this.select = !this.select;
  }

  selectType2() {
    this.select2 = !this.select2;
  }

  selectType3() {
    this.select3 = !this.select3;
  }

  selectType4() {
    this.select4 = !this.select4;
  }

  selectType5() {
    this.select5 = !this.select5;
  }
}
