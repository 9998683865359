import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-supplier-register-step4",
  templateUrl: "./supplier-register-step4.component.html",
  styleUrls: ["./supplier-register-step4.component.scss"],
})
export class SupplierRegisterStep4Component implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  select = false;
  cities: any[] = [];
  types: any[] = [];
  supplierForm: FormGroup;
  brands = [];

  constructor(private shanapService: ShanabService, private fb: FormBuilder) {}

  ngOnInit() {
    this.supplierForm = this.fb.group({
      name: ["", Validators.required],
      city: ["", Validators.required],
      types: ["", Validators.required],
      brands: ["", Validators.required],
      bio: [""],
    });
    this.getAllCities();
    this.getAllTypes();

    this.shanapService.getCarModels().subscribe((data) => {
      this.brands = data.data;
    });
  }

  getAllCities() {
    this.shanapService.getAllCities().subscribe((res: any) => {
      if (res) {
        this.cities = res.data.map((res: any) => {
          return { label: res.name, value: res._id };
        });
      }
    });
  }

  getAllTypes() {
    this.shanapService.getAllTypes(undefined).subscribe((res: any) => {
      if (res) {
        this.types = res.data;
      }
    });
  }

  selectType(i) {
    this.types[i].select = !this.types[i].select ? true : false;
    let selected = [];
    this.types.forEach((res: any) => {
      if (res.select) {
        selected.push(res._id);
      }
    });
    this.supplierForm.get("types").setValue(selected);
  }
  selectBrand(i) {
    this.brands[i].select = !this.brands[i].select ? true : false;

    let selected = [];
    this.brands.forEach((res: any) => {
      if (res.select) {
        selected.push(res._id);
      }
    });
    this.supplierForm.get("brands").setValue(selected);
  }

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    if (
      this.supplierForm.get("brands").value.length > 0 &&
      this.supplierForm.get("types").value.length
    ) {
      this.nextChange.emit(this.supplierForm.value);
    }
  }
}
