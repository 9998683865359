import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-place-direct-step1',
  templateUrl: './place-direct-step1.component.html',
  styleUrls: ['./place-direct-step1.component.scss']
})
export class PlaceDirectStep1Component implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    this.nextChange.emit();
  }


}
