import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { ShanabService } from "src/app/services/shanab.service";

@Injectable({
  providedIn: "root"
})
export class SimpleFilterService {
  private filterSubject = new BehaviorSubject<any>({
    city: "",
    brand: "",
    model: "",
    type: "",
    underWarrenty: "",
    minprice: "",
    maxprice: "",
    minyear: "",
    maxyear: "",
    minrange: "",
    maxrange: "",
    user: "",
    page: 1,
    pageSize: 10,
    total: 0
  });

  private years = new BehaviorSubject<string[]>(this.generateYears(1990, 2021));

  private carTypes = new BehaviorSubject<string[]>([
    "SUV",
    "Crossover",
    "Sedan",
    "PickUp",
    "Coupe",
    "Convertible",
    "Van",
    "Truck",
    "Bus",
    "Other"
  ]);

  private brands = new BehaviorSubject<any[]>([]);
  private filteredBrands = new BehaviorSubject<any[]>([]);
  private filteredCarTypes = new BehaviorSubject<any[]>([]);
  private filteredYearsMin = new BehaviorSubject<any[]>([]);
  private filteredYearsMax = new BehaviorSubject<any[]>([]);

  filter$ = this.filterSubject.asObservable();
  years$ = this.years.asObservable();
  filteredCarTypes$ = this.filteredCarTypes.asObservable();
  filteredYearsMin$ = this.filteredYearsMin.asObservable();
  filteredYearsMax$ = this.filteredYearsMax.asObservable();
  filteredBrands$ = this.filteredBrands.asObservable();

  constructor(private shanapService: ShanabService, private router: Router) {
    this.getCarModels();
    this.carTypes.subscribe((data) => {
      this.filteredCarTypes.next(data);
    });
    this.years.subscribe((data) => {
      this.filteredYearsMin.next(data);
      this.filteredYearsMax.next(data);
    });
    console.log();
  }

  updateFilter(newFilter: any) {
    this.filterSubject.next(newFilter);
  }

  getAllCities() {
    return this.shanapService.getAllCities();
  }

  getAllSellCars(filter: any) {
    return this.shanapService.getAllSellCars(filter);
  }

  getCarModels() {
    this.shanapService.getCarModels().subscribe((data) => {
      this.brands.next(data.data);
      this.filteredBrands.next(data.data);
    });
  }

  navigateTo = (url: string, queryParams?: any) => {
    this.router.navigate([url], { queryParams });
  };

  filterBrands = (val) => {
    this.brands.subscribe((brands) => {
      this.filteredBrands.next(
        brands.filter((b) => b.title.toLowerCase().includes(val.toLowerCase()))
      );
    });
  };

  filterCarTypes = (val) => {
    this.carTypes.subscribe((types) => {
      this.filteredCarTypes.next(
        types.filter((b) => b.toLowerCase().includes(val.toLowerCase()))
      );
    });
  };

  filterYearsMin = (val) => {
    this.years.subscribe((types) => {
      this.filteredYearsMin.next(
        types.filter((b) => b.toLowerCase().includes(val.toLowerCase()))
      );
    });
  };

  filterYearsMax = (val) => {
    this.years.subscribe((types) => {
      this.filteredYearsMax.next(
        types.filter((b) => b.toLowerCase().includes(val.toLowerCase()))
      );
    });
  };

  resetVariables() {
    this.getCarModels();
    this.carTypes.subscribe((data) => {
      this.filteredCarTypes.next(data);
    });
    this.years.subscribe((data) => {
      this.filteredYearsMin.next(data);
      this.filteredYearsMax.next(data);
    });
  }

  generateYears(start: number, end: number) {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i).map(
      (y) => y.toString()
    );
  }
}
