import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MenuHomeComponent } from "./home-components/menu-home/menu-home.component";
import { HeaderHomeComponent } from "./home-components/header-home/header-home.component";
import { HowWorkHomeComponent } from "./home-components/how-work-home/how-work-home.component";
import { RegisterHomeComponent } from "./home-components/register-home/register-home.component";
import { OurShopsHomeComponent } from "./home-components/our-shops-home/our-shops-home.component";
import { DownloadHomeComponent } from "./home-components/download-home/download-home.component";
import { ContactHomeComponent } from "./home-components/contact-home/contact-home.component";
import { InformationHomeComponent } from "./home-components/information-home/information-home.component";
import { FooterHomeComponent } from "./home-components/footer-home/footer-home.component";
import { HeaderSignInComponent } from "./sign-in-components/header-sign-in/header-sign-in.component";
import { Step1RegisrationComponent } from "./registeration/step1-regisration/step1-regisration.component";
import { Step2RegisrationComponent } from "./registeration/step2-regisration/step2-regisration.component";
import { Step3RegisrationComponent } from "./registeration/step3-regisration/step3-regisration.component";
import { Step4RegisrationComponent } from "./registeration/step4-regisration/step4-regisration.component";
import { CompletRegisrationComponent } from "./registeration/complet-regisration/complet-regisration.component";
import { FormatTimePipe } from "../pipes/format-time.pipe";
import { VerifycationOtpComponent } from "./verifycation-otp/verifycation-otp.component";
import { PlaceOrderStep2Component } from "./placeOrder/place-order-step2/place-order-step2.component";
import { PlaceOrderStep1Component } from "./placeOrder/place-order-step1/place-order-step1.component";
import { PlaceOrderStep3Component } from "./placeOrder/place-order-step3/place-order-step3.component";
import { MyOrdersHeaderComponent } from "./userOrder/my-orders-header/my-orders-header.component";
import { MyOrdersSegmentComponent } from "./userOrder/my-orders-segment/my-orders-segment.component";
import { MyOrdersCardComponent } from "./userOrder/my-orders-card/my-orders-card.component";
import { CardWorkshopComponent } from "./card-workshop/card-workshop.component";
import { WorkshopUserDetailsComponent } from "./workshop-user-details/workshop-user-details.component";
import { WorkshopUserDetailsCardComponent } from "./workshop-user-details-card/workshop-user-details-card.component";
import { WorkshopUserDetailsCommentComponent } from "./workshop-user-details-comment/workshop-user-details-comment.component";
import { PlaceDirectStep1Component } from "./placeDirectOrder/place-direct-step1/place-direct-step1.component";
import { PlaceDirectOrderMapComponent } from "./placeDirectOrder/place-direct-order-map/place-direct-order-map.component";
import { PlaceDirectOrderStep2Component } from "./placeDirectOrder/place-direct-order-step2/place-direct-order-step2.component";
import { PlaceDirectOrderStep3Component } from "./placeDirectOrder/place-direct-order-step3/place-direct-order-step3.component";
import { VehiclesAddCarComponent } from "./vehicles-add-car/vehicles-add-car.component";
import { MycarCardComponent } from "./userOrder/mycar-card/mycar-card.component";
import { HelpButtonsComponent } from "./help-buttons/help-buttons.component";
import { TicketsComponent } from "./tickets/tickets.component";
import { SelectServicesButtonsComponent } from "./select-services-buttons/select-services-buttons.component";
import { OrderDetailsCardComponent } from "./userOrder/order-details-card/order-details-card.component";
import { CardSelectCarFixComponent } from "./userOrder/card-select-car-fix/card-select-car-fix.component";
import { AgmCoreModule } from "@agm/core";
import { BackNextButtonsComponent } from "./back-next-buttons/back-next-buttons.component";
import { OrdeOffersCardValueComponent } from "./orde-offers-card-value/orde-offers-card-value.component";
import { OrderOffersAfterPayCardComponent } from "./order-offers-after-pay-card/order-offers-after-pay-card.component";
import { OfferAccepetCardComponent } from "./offer-accepet-card/offer-accepet-card.component";
import { ZoneCardComponent } from "./zone-card/zone-card.component";
import { OfferWorkshopRateComponent } from "./offer-workshop-rate/offer-workshop-rate.component";
import { ProfileButtonsComponent } from "./profile-buttons/profile-buttons.component";
import { SupplierRegisterStep2Component } from "./registeration/supplier-register-step2/supplier-register-step2.component";
import { SupplierRegisterStep3Component } from "./registeration/supplier-register-step3/supplier-register-step3.component";
import { SupplierRegisterStep4Component } from "./registeration/supplier-register-step4/supplier-register-step4.component";
import { SupplierRegisterStep5Component } from "./registeration/supplier-register-step5/supplier-register-step5.component";
import { SupplierRegisterStep6Component } from "./registeration/supplier-register-step6/supplier-register-step6.component";
import { RequestsNotActiveComponent } from "./supplierComponents/requests-not-active/requests-not-active.component";
import { CompleteRegistirationComponent } from "./supplierComponents/complete-registiration/complete-registiration.component";
import { GoogleMapComponent } from "./google-map/google-map.component";
import { StatusComponent } from "./status/status.component";
import { ConfirmPopUpComponent } from "./supplierComponents/confirm-pop-up/confirm-pop-up.component";
import { ToastrModule } from "ng6-toastr-notifications";
import { LoaderComponent } from "./loader/loader.component";
import { NoDataComponent } from "./no-data/no-data.component";
import { CarFilterComponent } from "./car-filter/car-filter.component";
import { ModalFilterComponent } from "./modal-filter/modal-filter.component";

@NgModule({
  declarations: [
    CarFilterComponent,
    MenuHomeComponent,
    HeaderHomeComponent,
    HowWorkHomeComponent,
    RegisterHomeComponent,
    OurShopsHomeComponent,
    DownloadHomeComponent,
    ContactHomeComponent,
    InformationHomeComponent,
    FooterHomeComponent,
    HeaderSignInComponent,
    Step1RegisrationComponent,
    Step2RegisrationComponent,
    Step3RegisrationComponent,
    Step4RegisrationComponent,
    CompletRegisrationComponent,
    FormatTimePipe,
    VerifycationOtpComponent,
    PlaceOrderStep2Component,
    PlaceOrderStep1Component,
    PlaceOrderStep3Component,
    MyOrdersHeaderComponent,
    MyOrdersSegmentComponent,
    MyOrdersCardComponent,
    CardWorkshopComponent,
    WorkshopUserDetailsComponent,
    WorkshopUserDetailsCardComponent,
    WorkshopUserDetailsCommentComponent,
    PlaceDirectStep1Component,
    PlaceDirectOrderMapComponent,
    PlaceDirectOrderStep2Component,
    PlaceDirectOrderStep3Component,
    VehiclesAddCarComponent,
    MycarCardComponent,
    HelpButtonsComponent,
    TicketsComponent,
    SelectServicesButtonsComponent,
    OrderDetailsCardComponent,
    CardSelectCarFixComponent,
    BackNextButtonsComponent,
    OrdeOffersCardValueComponent,
    OrderOffersAfterPayCardComponent,
    OfferAccepetCardComponent,
    ZoneCardComponent,
    OfferWorkshopRateComponent,
    ProfileButtonsComponent,
    SupplierRegisterStep2Component,
    SupplierRegisterStep3Component,
    SupplierRegisterStep4Component,
    SupplierRegisterStep5Component,
    SupplierRegisterStep6Component,
    RequestsNotActiveComponent,
    CompleteRegistirationComponent,
    GoogleMapComponent,
    StatusComponent,
    ConfirmPopUpComponent,
    LoaderComponent,
    NoDataComponent,
    ModalFilterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey:
        "AIzaSyDCFEra_OySJFJsOHHMoJ8sU-KLqOr-ppM&callback=initMap&language=ar"
    })
  ],
  exports: [
    MenuHomeComponent,
    HeaderHomeComponent,
    HowWorkHomeComponent,
    RegisterHomeComponent,
    OurShopsHomeComponent,
    DownloadHomeComponent,
    ContactHomeComponent,
    InformationHomeComponent,
    FooterHomeComponent,
    HeaderSignInComponent,
    Step1RegisrationComponent,
    Step2RegisrationComponent,
    Step3RegisrationComponent,
    Step4RegisrationComponent,
    CompletRegisrationComponent,
    FormatTimePipe,
    VerifycationOtpComponent,
    PlaceOrderStep2Component,
    PlaceOrderStep1Component,
    PlaceOrderStep3Component,
    MyOrdersHeaderComponent,
    MyOrdersSegmentComponent,
    MyOrdersCardComponent,
    CardWorkshopComponent,
    WorkshopUserDetailsComponent,
    WorkshopUserDetailsCardComponent,
    WorkshopUserDetailsCommentComponent,
    PlaceDirectStep1Component,
    PlaceDirectOrderMapComponent,
    PlaceDirectOrderStep2Component,
    PlaceDirectOrderStep3Component,
    VehiclesAddCarComponent,
    MycarCardComponent,
    HelpButtonsComponent,
    TicketsComponent,
    SelectServicesButtonsComponent,
    OrderDetailsCardComponent,
    CardSelectCarFixComponent,
    BackNextButtonsComponent,
    OrdeOffersCardValueComponent,
    OrderOffersAfterPayCardComponent,
    OfferAccepetCardComponent,
    ZoneCardComponent,
    OfferWorkshopRateComponent,
    ProfileButtonsComponent,
    SupplierRegisterStep2Component,
    SupplierRegisterStep3Component,
    SupplierRegisterStep4Component,
    SupplierRegisterStep5Component,
    SupplierRegisterStep6Component,
    RequestsNotActiveComponent,
    CompleteRegistirationComponent,
    StatusComponent,
    ConfirmPopUpComponent,
    LoaderComponent,
    NoDataComponent,
    GoogleMapComponent,
    CarFilterComponent,
    ModalFilterComponent
  ]
})
export class SharedModule {}
