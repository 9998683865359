import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-select-services-buttons",
  templateUrl: "./select-services-buttons.component.html",
  styleUrls: ["./select-services-buttons.component.scss"]
})
export class SelectServicesButtonsComponent implements OnInit {
  @Input("btnConfirm") btnConfirm = false;
  select = false;
  select2 = false;
  select3 = false;
  select4 = false;
  select5 = false;
  orderTypes: any[] = [];
  workShopServices: any[] = [];
  shanabServices: any[] = [];
  serviceType: string = "normal";
  title = "";
  @Output() selectedType = new EventEmitter();

  constructor(private shanabService: ShanabService) {}

  ngOnInit() {
    this.serviceType = localStorage.getItem("auth-subscriptionType");
    console.log("serviceType", this.serviceType);
    this.getAllTypes();
  }

  getAllTypes() {
    this.shanabService.getAllTypes(this.serviceType).subscribe((res: any) => {
      if (res) {
        this.orderTypes = res.data;
        this.title = res.title;
        this.shanabServices = this.orderTypes.filter(
          (type) => type.serviceType !== "Workshop"
        );
        console.log(this.shanabServices);
        this.workShopServices = this.orderTypes.filter(
          (type) => type.serviceType === "Workshop"
        );
        console.log(this.workShopServices);
      }
    });
  }

  selectType(index) {
    this.orderTypes.forEach((type: any, i) => {
      if (index == i) {
        this.selectedType.emit(this.orderTypes[i]._id);
        this.orderTypes[i].select = true;
      } else {
        this.orderTypes[i].select = false;
      }
    });
  }

  setWorkShopOrderType(index: any) {
    this.workShopServices.forEach((type: any, i) => {
      if (index == i) {
        this.shanabServices.map(
          (shanabShopService) => (shanabShopService.select = false)
        );
        this.workShopServices[i].price = "";
        this.workShopServices[i].flag = false;
        this.selectedType.emit(this.workShopServices[i]);
        this.workShopServices[i].select = true;
      } else {
        this.workShopServices[i].select = false;
      }
    });
  }

  setShanabOrderType(index: any) {
    this.shanabServices.forEach((type: any, i) => {
      if (index == i) {
        this.workShopServices.map(
          (workShopService) => (workShopService.select = false)
        );

        this.selectedType.emit(this.shanabServices[i]);
        this.shanabServices[i].select = true;
      } else {
        this.shanabServices[i].select = false;
      }
    });
  }
}
