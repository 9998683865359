import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-sell-your-car",
  templateUrl: "./sell-your-car.component.html",
  styleUrls: ["./sell-your-car.component.scss"]
})
export class SellYourCarComponent implements OnInit {
  @Output() changeSegment = new EventEmitter();
  selected = true;
  isAuth: boolean = !!localStorage.getItem("auth-token");
  cities = [];
  cars = [];
  // paging = {
  //   currentPage: 1,
  //   pageSize: 10,
  //   total: 0,
  // };
  filter = {
    city: "",
    brand: "",
    model: "",
    type: "",
    underWarrenty: "",
    minprice: "",
    maxprice: "",
    minyear: "",
    maxyear: "",
    minrange: "",
    maxrange: "",
    user: "",
    page: 1,
    pageSize: 10,
    total: 0
  };

  carTypes = [
    "SUV",
    "Crossover",
    "Sedan",
    "PickUp",
    "Coupe",
    "Convertible",
    "Van",
    "Truck",
    "Bus",
    "Other"
  ];
  conditions = ["New", "Used", "Other"];
  brands = [];
  models = [];
  loading = true;
  more = true;

  years = [
    "1990",
    "1991",
    "1992",
    "1993",
    "1994",
    "1995",
    "1996",
    "1997",
    "1998",
    "1999",
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021"
  ];

  constructor(private shanapService: ShanabService, private router: Router) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.getAllCities();
    this.getAllSellCars();
    this.getAllBrands();
  }

  changeBrand() {
    this.models = this.brands.find((e) => e._id == this.filter.brand)
      ? this.brands.find((e) => e._id == this.filter.brand).children
      : [];
  }

  getAllBrands() {
    this.shanapService.getCarModels().subscribe((data) => {
      this.brands = data.data;
    });
  }

  onClick(type) {
    this.filter.user = type == 1 ? "" : "true";
    this.getAllSellCars();

    this.selected = !this.selected;
    // this.changeSegment.emit();
  }

  getAllCities() {
    this.shanapService.getAllCities().subscribe((res: any) => {
      if (res) {
        this.cities = res.data;
      }
    });
  }
  getAllSellCars() {
    this.loading = true;
    const minPriceFilter: number = +this.filter.minprice;
    const maxPriceFilter: number = +this.filter.maxprice;
    const minRangeFilter: number = +this.filter.minrange;
    const maxRangeFilter: number = +this.filter.maxrange;
    const minYearFilter: number = +this.filter.minyear;
    const maxYearFilter: number = +this.filter.maxyear;

    // if (this.filter.user) {
    // this.shanapService
    //   .getMySellCars(
    //     this.filter
    //   )
    //   .subscribe((res: any) => {

    //       this.cars = res.data;
    //       this.filter = {...this.filter,  ...res.paging};

    // this.loading = false;

    //   }, () => {
    // this.loading = false;

    //   });

    // } else {
    if (
      minPriceFilter > maxPriceFilter ||
      maxRangeFilter > minRangeFilter ||
      minYearFilter > maxYearFilter
    ) {
      this.loading = false;
      return false;
    }
    this.shanapService.getAllSellCars(this.filter).subscribe(
      (res: any) => {
        this.cars = res.data;
        this.filter = { ...this.filter, ...res.paging };
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );

    // }
  }

  changePage(e) {
    this.filter.page = e;
    this.getAllSellCars();
  }

  addcar() {
    if (this.isAuth) {
      this.router.navigateByUrl("/add-sell-car");
    } else {
      this.router.navigateByUrl("/login");
    }
  }

  moreOrLess() {
    this.more = !this.more;
  }

  clearFilter() {
    this.filter = {
      city: "",
      brand: "",
      model: "",
      type: "",
      underWarrenty: "",
      minprice: "",
      maxprice: "",
      minyear: "",
      maxyear: "",
      minrange: "",
      maxrange: "",
      user: "",
      page: 1,
      pageSize: 10,
      total: 0
    };

    this.getAllSellCars();
  }
}
