import { Component, OnInit } from '@angular/core';
import { ShanabService } from 'src/app/services/shanab.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  privacy: any;

  constructor(private shanab: ShanabService) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.shanab.settings.subscribe((data: any) => {
      if (data) {        
        this.privacy = data.policy[0].value;
      }
    });
  }
}
