import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-suplier-brands",
  templateUrl: "./suplier-brands.component.html",
  styleUrls: ["./suplier-brands.component.scss"],
})
export class SuplierBrandsComponent implements OnInit {
  user: any;
  brands = [];

  allBrands = [];
  constructor(private auth: AuthService, private shanab: ShanabService) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.shanab.getCarModels().subscribe((data) => {
      this.allBrands = data.data;
    });
    this.auth.currentUser.subscribe((data) => {
      if (data) {
        this.user = data;

        this.brands = this.user.brands.map((e) => e._id);
      }
    });
  }

  selectbrand(brand) {
    if (this.checkSelected(brand._id)) {
      this.brands = this.brands.filter((e) => e != brand._id);
    } else {
      this.brands.push(brand._id);
    }
  }

  checkSelected(id) {
    return this.brands.includes(id) ? true : false;
  }

  update() {
    this.shanab
      .updateSupplierProfile({ brands: this.brands })
      .subscribe((data) => {
        this.auth.currentUser.next(data.data);
      });
  }
}
