import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workshop-user-details-card',
  templateUrl: './workshop-user-details-card.component.html',
  styleUrls: ['./workshop-user-details-card.component.scss']
})
export class WorkshopUserDetailsCardComponent implements OnInit {
  select = false;
  select2 = false;
  select3 = false;
  select4 = false;
  select5 = false;
  constructor(private router: Router) { }

  ngOnInit() {
  }
  selectType() {
    this.select = !this.select;
  }

  selectType2() {
    this.select2 = !this.select2;
  }

  selectType3() {
    this.select3 = !this.select3;
  }

  selectType4() {
    this.select4 = !this.select4;
  }

  selectType5() {
    this.select5 = !this.select5;
  }

  goToPlaceOrder() {
    this.router.navigate(['place-direct-order']);
  }
}
