import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-step1-regisration',
  templateUrl: './step1-regisration.component.html',
  styleUrls: ['./step1-regisration.component.scss']
})
export class Step1RegisrationComponent implements OnInit {
  @Output() carOwnerClicked = new EventEmitter();
  @Output() supplierClicked = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  carOwnerClick() {
    this.carOwnerClicked.emit();
  }

  supplierClick() {
    this.supplierClicked.emit();
  }
}
