import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-card-select-car-fix",
  templateUrl: "./card-select-car-fix.component.html",
  styleUrls: ["./card-select-car-fix.component.scss"]
})
export class CardSelectCarFixComponent implements OnInit {
  selectcar = false;
  selectcar2 = false;
  image: any;
  addimage = false;
  sendText = "Next";
  show: boolean = false;
  images = [];
  orderData: any = { images: [], customBrand: false };
  cars: any[] = [];
  @Input("selectedService") selectedService;

  selectedCar: any;
  setting: any;

  constructor(private router: Router, private shanabService: ShanabService) {}

  ngOnInit() {
    this.serviceSelection();
    this.getAllCars();
    this.shanabService.settings.subscribe((data) => {
      this.setting = data;
    });
  }

  serviceSelection() {
    console.log(this.selectedService);
    if (this.selectedService.flag == true) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  getAllCars() {
    this.shanabService.getAllCars().subscribe((res: any) => {
      if (res) {
        this.cars = res.data;
      }
    });
  }

  getData() {
    this.orderData.show = this.show;
    return this.orderData;
  }

  goToAddCar() {
    this.router.navigate(["/vehicles"]);
  }

  selectCar(index) {
    this.cars.forEach((type: any, i) => {
      if (index == i) {
        this.selectedCar = this.cars[i];
        this.orderData.myCar = this.cars[i]._id;
        this.orderData.orderBrand = this.cars[i].brand._id;

        this.cars[i].selectcar = true;
      } else {
        this.cars[i].selectcar = false;
      }
    });
  }

  chooseImage(event) {
    const image: File = event.target.files[0];
    this.orderData.images.push(image);

    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.images.push(this.image);
    };
    myReader.readAsDataURL(image);
    this.addimage = true;
    this.sendText = "Send Order";
  }
}
