import { Injectable } from "@angular/core";
import { HttpInitService } from "./http-init.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RequestsService {
  constructor(private initService: HttpInitService) {}

  getAllRequests(data: any): Observable<any> {
    return this.initService.getWithParamsRequest(
      "order/frontend/supplier",
      data
    );
  }

  addRequest(data: any): Observable<any> {
    return this.initService.postRequest("offer/frontend/supplier", data);
  }

  uploadFile(file: any): Observable<any> {
    return this.initService.uploadRequest("upload/images/", file);
  }

  updateSupplierProfile(data): Observable<any> {
    return this.initService.putRequest("otp/updateProfile/supplier", data);
  }

  updateProfile(data): Observable<any> {
    return this.initService.putRequest("otp/updateProfile", data);
  }
}
