import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-supplier-register-step6",
  templateUrl: "./supplier-register-step6.component.html",
  styleUrls: ["./supplier-register-step6.component.scss"],
})
export class SupplierRegisterStep6Component implements OnInit {
  @Output() lastChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  image: any;
  imgFile: any;

  constructor() {}

  ngOnInit() {}

  backClicked() {
    this.backChange.emit();
  }

  lastClicked() {
    this.lastChange.emit(this.imgFile);
  }

  chooseImage(event) {
    const image: File = event.target.files[0];
    this.imgFile = event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
    };
    myReader.readAsDataURL(image);
  }
}
