import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-footer-home",
  templateUrl: "./footer-home.component.html",
  styleUrls: ["./footer-home.component.scss"],
})
export class FooterHomeComponent implements OnInit {
  setting: any;
  constructor(private settingService: ShanabService) {}

  ngOnInit() {
    this.settingService.settings.subscribe((data) => {
      this.setting = data;
    });
  }
}
