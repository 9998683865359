import { Component, OnInit, Input } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";

@Component({
  selector: "app-information-home",
  templateUrl: "./information-home.component.html",
  styleUrls: ["./information-home.component.scss"],
})
export class InformationHomeComponent implements OnInit {
  setting: any;
  constructor(private settingService: ShanabService) {}
  @Input() searchProp: string | undefined;

  ngOnInit() {
    this.settingService.settings.subscribe((data) => {
      this.setting = data;
    });
  }
}
