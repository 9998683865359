import { Component, OnInit } from "@angular/core";
import { ShanabService } from "src/app/services/shanab.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-pay-to-transport",
  templateUrl: "./pay-to-transport.component.html",
  styleUrls: ["./pay-to-transport.component.scss"],
})
export class PayToTransportComponent implements OnInit {
  offerId: string;
  paymentDetails: any = {};

  constructor(
    private shanabService: ShanabService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.offerId = this.route.snapshot.params.offerId;
    this.getPaymentDetails();
  }

  getPaymentDetails() {
    const location = JSON.parse(localStorage.getItem("location"));
    this.shanabService
      .getTransportPaymentDetails({
        point: location["coordinates"],
        offerId: this.offerId,
      })
      .subscribe((res) => {
        this.paymentDetails = res;
      });
  }

  pay() {
    window.open(this.paymentDetails.payment_url, "_self");
  }
}
