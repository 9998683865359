import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';

declare var google;

@Component({
  selector: 'app-place-direct-order-map',
  templateUrl: './place-direct-order-map.component.html',
  styleUrls: ['./place-direct-order-map.component.scss']
})

export class PlaceDirectOrderMapComponent implements OnInit {
  @Output() nextChange = new EventEmitter();
  @Output() backChange = new EventEmitter();
  // tslint:disable-next-line: no-output-rename
  @Output('location') location = new EventEmitter();
  // tslint:disable-next-line: no-input-rename
  @Input('currentLocation') currentLocation;
  map: any;
  marker: any;
  @ViewChild('mapElement', { static: true }) mapElement;
  mapOptions: any = {};

  constructor() { }

  ngOnInit() {
  }

  backClicked() {
    this.backChange.emit();
  }

  nextClicked() {
    this.nextChange.emit();
  }

  placeMarker(location) {
    this.marker.setPosition(location);
    this.location.emit({
      coordinates: [
        this.marker.getPosition().lng(),
        this.marker.getPosition().lat(),
      ],
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterContentInit(): void {
    this.mapOptions = {
      center: { lat: 24.774265, lng: 46.738586 },
      zoom: 8,
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e9e9e9',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dedede',
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#ffffff',
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              saturation: 36,
            },
            {
              color: '#333333',
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f2f2f2',
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fefefe',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#fefefe',
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
      ],
    };
    this.map = new google.maps.Map(
      this.mapElement.nativeElement,
      this.mapOptions
    );
    this.marker = new google.maps.Marker({
      position: this.mapOptions.center,
      map: this.map,
      icon: {
        url: '../../../../assets/img/map-marker.svg',
      },
      draggable: !this.currentLocation ? true : false,
      animation: google.maps.Animation.DROP,
      optimized: false,
    });

    const myoverlay = new google.maps.OverlayView();
    myoverlay.draw = function () {
      this.getPanes().markerLayer.id = 'markerLayer';
    };
    myoverlay.setMap(this.map);

    if (!this.currentLocation) {
      this.map.addListener('click', (event) => {
        this.placeMarker(event.latLng);
      });
      this.getCurrentLocation();
    } else {
      this.marker.setPosition(this.currentLocation);
      this.map.setCenter(this.currentLocation);
    }
  }

  getCurrentLocation() {
    //   this.geolocation
    //     .getCurrentPosition()
    //     .then((resp) => {
    //       const currentPosition = {
    //         lat: resp.coords.latitude,
    //         lng: resp.coords.longitude,
    //       };

    //       this.settingService.setValue("location", {
    //         coordinates: [resp.coords.longitude, resp.coords.latitude],
    //       });

    //       this.map.setCenter(currentPosition);
    //       this.map.setZoom(16);

    //       this.marker.setPosition(currentPosition);
    //       this.location.emit({
    //         coordinates: [
    //           this.marker.getPosition().lng(),
    //           this.marker.getPosition().lat(),
    //         ],
    //       });
    //     })
    //     .catch((error) => {});
    // }
  }
}
